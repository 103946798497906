export const GET_DASHBOARD_OVERVIEW_SHIPMENTCARD_DATA="GET_DASHBOARD_OVERVIEW_SHIPMENTCARD_DATA"
export const GET_DASHBOARD_OVERVIEW_NDR_DETAILS_DATA="GET_DASHBOARD_OVERVIEW_NDR_DETAILS_DATA"
export const GET_DASHBOARD_OVERVIEW_COD_DETAILS_DATA="GET_DASHBOARD_OVERVIEW_COD_DETAILS_DATA"
export const GET_DASHBOARD_OVERVIEW_RTO_DETAILS_DATA="GET_DASHBOARD_OVERVIEW_RTO_DETAILS_DATA"
export const GET_DASHBOARD_OVERVIEW_LAST_ORDERS_DATA="GET_DASHBOARD_OVERVIEW_LAST_ORDERS_DATA"
export const GET_DASHBOARD_OVERVIEW_REVENUE_CARD_DATA="GET_DASHBOARD_OVERVIEW_REVENUE_CARD_DATA"
export const GET_DASHBOARD_OVERVIEW_COUNTER_CARD_DATA="GET_DASHBOARD_OVERVIEW_COUNTER_CARD_DATA"
export const GET_DASHBOARD_OVERVIEW_TOPSELL_DATA="GET_DASHBOARD_OVERVIEW_TOPSELL_DATA"
export const GET_DASHBOARD_OVERVIEW_STATEWISE_DATA="GET_DASHBOARD_OVERVIEW_STATEWISE_DATA"
export const GET_DASHBOARD_OVERVIEW_COURIERWISE_ALLOCATION_DATA="GET_DASHBOARD_OVERVIEW_COURIERWISE_ALLOCATION_DATA"
export const GET_DASHBOARD_OVERVIEW_MOST_POPULAR_CUSTOMER_DATA="GET_DASHBOARD_OVERVIEW_MOST_POPULAR_CUSTOMER_DATA"
export const GET_DASHBOARD_OVERVIEW_DELIVERY_PERFORMANCE_DATA="GET_DASHBOARD_OVERVIEW_DELIVERY_PERFORMANCE_DATA"
export const GET_DASHBOARD_OVERVIEW_WEIGHT_DISPENCERY_DATA="GET_DASHBOARD_OVERVIEW_WEIGHT_DISPENCERY_DATA"
