import React from 'react'

const WeightDimensionIcon = () => {
    return (
        <>
            {/* <svg
                width={24}
                height={24}
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_878_2885)">
                    <path
                        d="M219.04 469.187C199.366 469.187 183.362 453.183 183.362 433.509V219.672C234.291 209.668 272.835 164.698 272.835 110.88C272.835 49.7402 223.095 0 161.955 0C100.815 0 51.0742 49.7402 51.0742 110.88C51.0742 164.697 89.619 209.667 140.548 219.671V433.509C140.548 476.787 175.759 512 219.039 512H460.927V469.187H219.04ZM161.955 193.222C116.553 193.222 79.6174 156.285 79.6174 110.879C79.6174 65.4784 116.554 28.5407 161.955 28.5407C207.356 28.5407 244.293 65.4775 244.293 110.879C244.293 156.286 207.356 193.222 161.955 193.222ZM254.226 229.959C232.168 229.959 214.285 247.842 214.285 269.901V403.085C214.285 425.144 232.168 443.025 254.226 443.025H404.259C426.318 443.025 444.2 425.143 444.2 403.085V269.901C444.2 247.842 426.318 229.959 404.259 229.959H343.597V260.023H315.259V229.959H254.226ZM385.36 400.593H273.127V386.424H385.359V400.593H385.36ZM365.412 282.395L393.562 315.952H373.242V359.578H357.581V315.952H337.259L365.412 282.395ZM321.227 315.952H300.905V359.578H285.244V315.952H264.924L293.074 282.395L321.227 315.952ZM156.078 122.775C149.51 123.918 143.258 119.52 142.114 112.953C140.971 106.385 145.368 100.133 151.936 98.9893C153.307 98.755 154.707 98.755 156.078 98.9893L224.369 110.882L156.078 122.775ZM161.954 60.7934C167.632 60.7934 172.235 56.1904 172.235 50.5124C172.235 44.8344 167.632 40.2314 161.954 40.2314C156.276 40.2314 151.673 44.8344 151.673 50.5124C151.673 56.1904 156.276 60.7934 161.954 60.7934ZM161.954 182.059C167.632 182.059 172.235 177.456 172.235 171.778C172.235 166.1 167.632 161.497 161.954 161.497C156.276 161.497 151.673 166.1 151.673 171.778C151.673 177.456 156.276 182.059 161.954 182.059ZM100.757 120.861C106.435 120.861 111.038 116.258 111.038 110.58C111.038 104.902 106.435 100.299 100.757 100.299C95.0787 100.299 90.4757 104.902 90.4757 110.58C90.4757 116.258 95.0778 120.861 100.757 120.861Z"
                        fill="#1975C9"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_878_2885">
                        <rect width={512} height={512} fill="white" />
                    </clipPath>
                </defs>
            </svg> */}
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.2871 17.495L16.9391 8.44701C16.8236 8.09819 16.601 7.79472 16.3029 7.5798C16.0049 7.36488 15.6466 7.24947 15.2791 7.25001H12.7501V5.75001H16.2191C17.0241 5.75001 17.7221 5.20501 17.9171 4.42401L18.4171 2.42401C18.4825 2.1661 18.4877 1.89662 18.4324 1.63638C18.3771 1.37613 18.2627 1.13206 18.0981 0.923012C17.9352 0.712661 17.7261 0.542571 17.487 0.425879C17.2479 0.309187 16.9852 0.249016 16.7191 0.250012H3.28114C2.73814 0.250012 2.23514 0.495012 1.90214 0.923012C1.73756 1.13206 1.62321 1.37613 1.5679 1.63638C1.51259 1.89662 1.5178 2.1661 1.58314 2.42401L2.08314 4.42401C2.1774 4.80293 2.39582 5.13938 2.70357 5.3797C3.01132 5.62003 3.39067 5.75039 3.78114 5.75001H7.25014V7.25001H4.72114C3.96614 7.25001 3.29914 7.73101 3.04714 8.49501L0.727136 17.446C0.640237 17.7092 0.616928 17.9892 0.659119 18.2631C0.701309 18.5369 0.807799 18.7969 0.969862 19.0217C1.13192 19.2465 1.34495 19.4297 1.59147 19.5563C1.838 19.6829 2.11101 19.7493 2.38814 19.75H17.6131C17.8906 19.7499 18.164 19.6839 18.4109 19.5573C18.6578 19.4307 18.8711 19.2472 19.0331 19.022C19.3611 18.567 19.4501 17.978 19.2871 17.495ZM14.5001 15C14.5001 15.965 13.7151 16.75 12.7501 16.75H7.25014C6.28514 16.75 5.50014 15.965 5.50014 15V14.75C5.50014 12.269 7.51914 10.25 10.0001 10.25C12.4811 10.25 14.5001 12.269 14.5001 14.75V15ZM13.0001 14.75V15C13.0001 15.136 12.8861 15.25 12.7501 15.25H10.7501V13C10.7501 12.8011 10.6711 12.6103 10.5305 12.4697C10.3898 12.329 10.199 12.25 10.0001 12.25C9.80122 12.25 9.61046 12.329 9.46981 12.4697C9.32915 12.6103 9.25014 12.8011 9.25014 13V15.25H7.25014C7.18407 15.2492 7.12094 15.2226 7.07422 15.1759C7.0275 15.1292 7.00092 15.0661 7.00014 15V14.75C7.00014 13.096 8.34614 11.75 10.0001 11.75C11.6541 11.75 13.0001 13.096 13.0001 14.75Z"
                    fill="#1975C9"
                />
            </svg>


        </>
    )
}

export default WeightDimensionIcon