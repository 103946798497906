import "./Dashboard.css";
import PnL from "./DashboardTabs/PnL";
import NavTabs from "./navTabs/NavTabs";
import { useSelector } from "react-redux";
import Overview from "./DashboardTabs/Overview";
import React, { useEffect, useState } from "react";
import SubAccounts from "./DashboardTabs/SubAccounts";
import NDRDashboard from "./DashboardTabs/NDRDashboard";
import RTODashboard from "./DashboardTabs/RTODashboard";
import WhatsappComm from "./DashboardTabs/WhatsappComm";
import EmployeeDash from "./DashboardTabs/EmployeeDash";
import LoaderScreen from "../../LoaderScreen/LoaderScreen";
import OrdersDashboard from "./DashboardTabs/OrdersDashboard";
import CourierDashboard from "./DashboardTabs/CourierDashboard";
import ShipmentDashboard from "./DashboardTabs/ShipmentDashboard";
import { useNavigate } from "react-router-dom";

export function clearAllCookies() {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

function Dashboard() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [activeTab, setActiveTab] = useState("Overview");
  const { checkAuthIsValid } = useSelector(state => state?.authDataReducer)

  useEffect(() => {
    setLoader(true)
    if (activeTab) {
      setTimeout(() => {
        setLoader(false)
      }, 230);
    }
  }, [activeTab])


  useEffect(() => {
    if (checkAuthIsValid) {
      clearAllCookies()
      navigate("/")
    }
  }, [checkAuthIsValid])


  return (
    <>
      <NavTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {/* overview */}
      <div className={`${activeTab === "Overview" ? "d-block" : "d-none"}`}>
        <Overview activeTab={activeTab} />
      </div>

      {/* Orders */}
      <div className={`${activeTab === "Orders" ? "d-block" : "d-none"}`}>
        <OrdersDashboard activeTab={activeTab} />
      </div>

      {/* Shipment */}
      <div className={`${activeTab === "Shipment" ? "d-block" : "d-none"}`}>
        <ShipmentDashboard activeTab={activeTab} />
      </div>

      {/* NDR */}
      <div className={`${activeTab === "NDR" ? "d-block" : "d-none"}`}>
        <NDRDashboard activeTab={activeTab} />
      </div>

      {/* RTO */}
      <div className={`${activeTab === "RTO" ? "d-block" : "d-none"}`}>
        <RTODashboard activeTab={activeTab} />
      </div>

      {/* Courier Delays */}
      <div className={`${activeTab === "Courier Delays" ? "d-block" : "d-none"}`}>
        <CourierDashboard activeTab={activeTab} />
      </div>

      {/* Whatsapp Comm */}
      <div className={`${activeTab === "Whatsapp Comm" ? "d-block" : "d-none"}`}>
        <WhatsappComm activeTab={activeTab} />
      </div>

      {/* SubAccounts*/}
      <div className={`${activeTab === "Sub Accounts" ? "d-block" : "d-none"}`}>
        <SubAccounts activeTab={activeTab} />
      </div>

      {/* Employees*/}
      <div className={`${activeTab === "Employees" ? "d-block" : "d-none"}`}>
        <EmployeeDash activeTab={activeTab} />
      </div>

      {/* Employees*/}
      <div className={`${activeTab === "P & L" ? "d-block" : "d-none"}`}>
        <PnL activeTab={activeTab} />
      </div>
      <LoaderScreen loading={loader} />
    </>
  );
}

export default Dashboard;
