import React from 'react'

const TicketIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 512.002 512.002"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        d="M12.505 298.672 155.49 155.931 298.735 12.932c16.685-16.657 43.834-16.657 60.52 0l.398.397c7.212 7.202 9.994 17.675 7.263 27.329a16.586 16.586 0 0 0 4.228 16.242c4.244 4.236 10.48 5.853 16.268 4.222 9.673-2.726 20.166.049 27.381 7.251l29.159 29.109c7.212 7.202 9.994 17.675 7.262 27.329a16.586 16.586 0 0 0 4.228 16.242 16.652 16.652 0 0 0 16.268 4.222c9.675-2.726 20.166.049 27.381 7.251l.397.395c16.685 16.657 16.685 43.759 0 60.416L356.938 355.64 213.255 499.079c-8.064 8.05-18.81 12.484-30.261 12.484s-22.197-4.434-30.26-12.484c-7.392-7.379-10.044-18.175-6.92-28.174a16.519 16.519 0 0 0-4.098-16.662c-4.373-4.366-10.771-5.933-16.688-4.092-10.021 3.118-20.835.47-28.225-6.908l-28.365-28.317c-7.392-7.379-10.044-18.175-6.92-28.174a16.516 16.516 0 0 0-4.098-16.661 16.581 16.581 0 0 0-16.688-4.093c-10.021 3.118-20.834.472-28.225-6.908C4.441 351.039 0 340.311 0 328.88c0-11.43 4.441-22.159 12.505-30.208z"
                        style={{}}
                        fill="#60a9eb"
                        data-original="#4596e6"
                        className="hovered-path"
                        opacity={1}
                    />
                    <path
                        d="M12.505 298.672 155.49 155.931l201.448 199.71-143.683 143.438c-8.064 8.05-18.81 12.484-30.261 12.484-11.45 0-22.197-4.434-30.26-12.484-7.392-7.379-10.044-18.175-6.92-28.174a16.519 16.519 0 0 0-4.098-16.662c-4.373-4.366-10.771-5.933-16.688-4.092-10.021 3.118-20.835.47-28.225-6.908l-28.365-28.317c-7.392-7.379-10.044-18.175-6.92-28.174a16.516 16.516 0 0 0-4.098-16.661 16.581 16.581 0 0 0-16.688-4.093c-10.021 3.118-20.834.472-28.225-6.908C4.441 351.039 0 340.311 0 328.88c0-11.43 4.441-22.159 12.505-30.208z"
                        style={{}}
                        fill="#1975c9"
                        data-original="#006df0"
                        className=""
                        opacity={1}
                    />
                    <path
                        d="M404.355 213.429c4.715 0 9.428-1.798 13.025-5.396 7.194-7.194 7.194-18.858 0-26.052l-45.36-45.361-45.824-45.823c-7.194-7.194-18.858-7.194-26.052 0-7.194 7.194-7.194 18.858 0 26.052l39.944 39.944 51.24 51.24a18.363 18.363 0 0 0 13.027 5.396zM341.049 222.583c4.715 0 9.428-1.798 13.025-5.396 7.194-7.194 7.194-18.858 0-26.052l-17.939-17.938-18.535-18.536c-7.194-7.194-18.858-7.193-26.052 0-7.194 7.194-7.194 18.858 0 26.052l15.221 15.22 21.253 21.253a18.367 18.367 0 0 0 13.027 5.397z"
                        style={{}}
                        fill="#d7ebff"
                        data-original="#d7ebff"
                        className=""
                    />
                    <path
                        d="m99.643 292.587 35.605-35.605c6.251-6.251 14.579-9.695 23.447-9.695 8.868 0 17.195 3.444 23.447 9.695l70.341 70.341c6.251 6.251 9.695 14.579 9.695 23.447 0 8.868-3.444 17.195-9.695 23.447l-35.605 35.605c-6.251 6.251-14.579 9.695-23.447 9.695-8.868 0-17.195-3.444-23.447-9.695l-70.341-70.341c-6.251-6.251-9.695-14.579-9.695-23.447s3.443-17.196 9.695-23.447zM300.145 90.799c7.194-7.194 18.858-7.194 26.052 0l45.824 45.823-25.85 26.255-46.026-46.026c-7.193-7.194-7.193-18.858 0-26.052zM291.548 154.661c7.194-7.194 18.858-7.194 26.052 0l18.422 18.422-25.85 26.255-18.626-18.624c-7.191-7.195-7.191-18.858.002-26.053z"
                        style={{}}
                        fill="#bddeff"
                        data-original="#bddeff"
                        className=""
                    />
                </g>
            </svg>


        </>
    )
}

export default TicketIcon