import React from 'react'

const QuickShipIcon = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={35}
                height={35}
                x={0}
                y={0}
                viewBox="0 0 25 25"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <g data-name="Fast Delivery">
                        <path
                            d="M17.144 18.354h-1.47a.5.5 0 0 1 0-1h1.47a.5.5 0 0 1 0 1zM20.474 18.354H19.4a.5.5 0 1 1 0-1h1.07a.915.915 0 0 0 .787-.631l.722-2.68c.02-.075.04-.211-.054-.273a2.593 2.593 0 0 1-1.144-1.97l-.036-.513c-.015-.122-.1-.2-.264-.2h-2.85a.5.5 0 0 1 0-1h2.85a1.229 1.229 0 0 1 1.259 1.1l.037.522a1.608 1.608 0 0 0 .7 1.217 1.209 1.209 0 0 1 .469 1.369l-.722 2.679a1.907 1.907 0 0 1-1.75 1.38zM6.11 12.722a.507.507 0 0 1-.133-.018.5.5 0 0 1-.349-.615l.3-1.09a.5.5 0 1 1 .964.266l-.3 1.089a.5.5 0 0 1-.482.368zM6.45 18.352H4.59a.5.5 0 0 1-.483-.63l.98-3.631a.5.5 0 0 1 .966.261l-.81 3H6.45a.5.5 0 0 1 0 1z"
                            fill="#1975C9"
                            opacity={1}
                            data-original="#000000"
                            className=""
                        />
                        <path
                            d="M15.67 18.352H8.71a.5.5 0 0 1 0-1h6.577l2.21-8.18H7.457l-.023.086a.5.5 0 1 1-.968-.252l.12-.46a.5.5 0 0 1 .484-.374h11.08a.5.5 0 0 1 .482.631l-2.479 9.179a.5.5 0 0 1-.483.37zM17.885 19.808a1.389 1.389 0 0 1-1.121-.525 1.455 1.455 0 0 1-.216-1.276 2.141 2.141 0 0 1 1.968-1.539 1.392 1.392 0 0 1 1.122.524 1.456 1.456 0 0 1 .216 1.276 2.142 2.142 0 0 1-1.969 1.54zm.631-2.34a1.145 1.145 0 0 0-1 .8.463.463 0 0 0 .044.407.4.4 0 0 0 .327.133 1.146 1.146 0 0 0 1-.8.466.466 0 0 0-.044-.407.4.4 0 0 0-.327-.133z"
                            fill="#000000"
                            opacity={1}
                            data-original="#000000"
                            className=""
                        />
                        <path
                            d="M7.194 19.808a1.389 1.389 0 0 1-1.121-.525 1.455 1.455 0 0 1-.216-1.276 2.139 2.139 0 0 1 1.968-1.539 1.389 1.389 0 0 1 1.121.524 1.456 1.456 0 0 1 .217 1.276 2.142 2.142 0 0 1-1.969 1.54zm.631-2.34a1.144 1.144 0 0 0-1 .8.463.463 0 0 0 .044.407.4.4 0 0 0 .327.133 1.147 1.147 0 0 0 1-.8.465.465 0 0 0-.045-.408.4.4 0 0 0-.326-.132zM9.9 11.633H2.5a.5.5 0 0 1 0-1h7.4a.5.5 0 0 1 0 1zM8.032 14.724H3.5a.5.5 0 0 1 0-1h4.532a.5.5 0 0 1 0 1zM9.771 6.192h-4.93a.5.5 0 0 1 0-1h4.93a.5.5 0 0 1 0 1zM19.249 6.842h-4.013a.5.5 0 0 1 0-1h4.013a.5.5 0 0 1 0 1zM13.382 6.842H12.5a.5.5 0 0 1 0-1h.882a.5.5 0 0 1 0 1zM11.052 15.456a.5.5 0 0 1-.369-.838l.992-1.084h-.9a.5.5 0 0 1-.382-.823l1.235-1.462a.5.5 0 1 1 .764.645l-.54.64h.958a.5.5 0 0 1 .369.837l-1.757 1.922a.5.5 0 0 1-.37.163z"
                            fill="#000000"
                            opacity={1}
                            data-original="#000000"
                            className=""
                        />
                    </g>
                </g>
            </svg> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 64 64"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        fill="#c5dcf1"
                        d="M43 21h4l-4 33H3l4-33h4"
                        opacity={1}
                        data-original="#f4f4f2"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M43 21h-1.71l-3.08 26.23a2 2 0 0 1-2 1.76H3.61L3 54h40l4-33z"
                        opacity={1}
                        data-original="#6f777f"
                        className="hovered-path"
                    />
                    <path
                        fill="#1975c9"
                        d="M54 30h-8.09L43 54l14 .02h4V36z"
                        opacity={1}
                        data-original="#356baa"
                        className=""
                    />
                    <path
                        fill="#85b9e4"
                        d="M57 38.36 52 34h-5v12h10z"
                        opacity={1}
                        data-original="#85b9e4"
                        className=""
                    />
                    <circle
                        cx={52}
                        cy={54}
                        r={5}
                        fill="#484c4f"
                        opacity={1}
                        data-original="#484c4f"
                        className=""
                    />
                    <circle
                        cx={12}
                        cy={54}
                        r={5}
                        fill="#484c4f"
                        opacity={1}
                        data-original="#484c4f"
                        className=""
                    />
                    <circle
                        cx={27}
                        cy={21}
                        r={16}
                        fill="#1975c9"
                        opacity={1}
                        data-original="#e34121"
                        className=""
                    />
                    <path
                        fill="#1975c9"
                        d="M27 5a16.52 16.52 0 0 0-2 .14 16 16 0 0 1 0 31.72 16.52 16.52 0 0 0 2 .14 16 16 0 0 0 0-32z"
                        opacity={1}
                        data-original="#cc3327"
                        className=""
                    />
                    <circle
                        cx={27}
                        cy={21}
                        r={12}
                        fill="#c5dcf1"
                        opacity={1}
                        data-original="#f4f4f2"
                        className=""
                    />
                    <path
                        fill="#c5dcf1"
                        d="M27 9a12 12 0 0 0-2 .18 12 12 0 0 1 0 23.64A12 12 0 1 0 27 9z"
                        opacity={1}
                        data-original="#b7b6b7"
                        className=""
                    />
                    <path
                        fill="#1975C9"
                        d="M27 22h8v-2h-7v-7h-2v8a1 1 0 0 0 1 1z"
                        opacity={1}
                        data-original="#14a8a4"
                        className=""
                    />
                    <path
                        fill="#c5dcf1"
                        d="M11 53h2v2h-2zM51 53h2v2h-2z"
                        opacity={1}
                        data-original="#f4f4f2"
                        className=""
                    />
                    <g fill="#6f777f">
                        <path
                            d="M5 12h18v2H5zM3 16h18v2H3zM5 28h18v2H5zM3 24h18v2H3z"
                            fill="#60a9eb"
                            opacity={1}
                            data-original="#6f777f"
                            className="hovered-path"
                        />
                    </g>
                </g>
            </svg>


        </>
    )
}

export default QuickShipIcon