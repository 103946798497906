import React from 'react'

const FacebookIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={24}
            height={24}
            x={0}
            y={0}
            viewBox="0 0 100 100"
            style={{ enableBackground: "new 0 0 512 512" }}
            xmlSpace="preserve"
            className=""
        >
            <g>
                <path
                    d="M40.4 55.2h-9.9c-1.6 0-2.1-.6-2.1-2.1V41c0-1.6.6-2.1 2.1-2.1h9.9v-8.8c0-4 .7-7.8 2.7-11.3 2.1-3.6 5.1-6 8.9-7.4 2.5-.9 5-1.3 7.7-1.3h9.8c1.4 0 2 .6 2 2v11.4c0 1.4-.6 2-2 2-2.7 0-5.4 0-8.1.1-2.7 0-4.1 1.3-4.1 4.1-.1 3 0 5.9 0 9h11.6c1.6 0 2.2.6 2.2 2.2V53c0 1.6-.5 2.1-2.2 2.1H57.3v32.6c0 1.7-.5 2.3-2.3 2.3H42.5c-1.5 0-2.1-.6-2.1-2.1V55.2z"
                    fill="#1975c9"
                    opacity={1}
                    data-original="#000000"
                    className=""
                />
            </g>
        </svg>

    )
}

export default FacebookIcon