import React from 'react'

const VerifiedOrderIcon = () => {
  return (
    <>
      <svg
        width={20}
        height={20}
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M452.387 213.102C445.458 209.074 439.233 203.944 433.955 197.913C434.488 189.517 436.488 181.278 439.864 173.572C446.072 156.057 453.091 136.217 441.827 120.793C430.563 105.369 409.293 105.86 390.627 106.286C382.378 107.135 374.046 106.572 365.987 104.622C361.693 97.6359 358.626 89.9661 356.92 81.9451C351.629 63.9184 345.592 43.5451 327.053 37.4438C309.176 31.6838 292.621 44.3558 277.987 55.4918C271.672 61.2586 264.355 65.8208 256.397 68.9531C248.357 65.8464 240.958 61.2829 234.573 55.4918C219.981 44.4198 203.491 31.6198 185.528 37.4651C167.032 43.4811 160.995 63.9184 155.661 81.9451C153.957 89.9401 150.921 97.5917 146.68 104.58C138.606 106.524 130.262 107.101 121.997 106.286C103.267 105.774 82.168 105.22 70.7973 120.793C59.4267 136.366 66.5307 156.057 72.76 173.55C76.1828 181.246 78.2125 189.487 78.7547 197.892C73.487 203.931 67.2683 209.069 60.344 213.102C44.728 223.769 27 235.908 27 255.62C27 275.332 44.728 287.428 60.344 298.137C67.2667 302.164 73.4853 307.295 78.7547 313.326C78.2695 321.728 76.2976 329.977 72.9307 337.689C66.744 355.182 59.7467 375.022 70.9893 390.446C82.232 405.87 103.437 405.38 122.189 404.953C130.445 404.104 138.784 404.666 146.851 406.617C151.125 413.611 154.184 421.279 155.896 429.294C161.187 447.321 167.224 467.694 185.763 473.796C188.735 474.749 191.836 475.238 194.957 475.246C209.959 473.094 223.902 466.272 234.808 455.748C241.123 449.981 248.44 445.419 256.397 442.286C264.438 445.393 271.836 449.957 278.221 455.748C292.835 466.905 309.389 479.641 327.288 473.774C345.784 467.758 351.821 447.321 357.155 429.316C358.864 421.306 361.923 413.645 366.2 406.66C374.243 404.702 382.561 404.125 390.797 404.953C409.528 405.401 430.627 406.02 441.997 390.446C453.368 374.873 446.264 355.182 440.035 337.668C436.635 329.98 434.607 321.756 434.04 313.369C439.311 307.325 445.537 302.186 452.472 298.158C468.088 287.492 485.816 275.332 485.816 255.62C485.816 235.908 468.024 223.79 452.387 213.102Z"
          fill="#00D25C"
        />
        <path
          d="M209.326 334C206.393 334.006 203.488 333.431 200.779 332.308C198.07 331.186 195.611 329.539 193.545 327.461L133.994 268.021C130.049 263.795 127.901 258.206 128.003 252.432C128.106 246.657 130.449 241.147 134.541 237.063C138.632 232.979 144.152 230.64 149.938 230.538C155.723 230.436 161.322 232.58 165.556 236.517L211.41 282.287L348.269 182.458C353.007 178.911 358.963 177.388 364.826 178.224C370.69 179.06 375.98 182.187 379.534 186.916C383.087 191.646 384.613 197.59 383.775 203.443C382.938 209.295 346.565 236.834 341.827 240.381L222.725 329.542C218.858 332.434 214.157 333.998 209.326 334Z"
          fill="white"
        />
      </svg>

    </>
  )
}

export default VerifiedOrderIcon