import React from 'react'

const RTOAddressIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M334.909 438.857C325.515 448.862 315.811 458.57 305.81 467.968L305.615 468.151C292.109 480.635 274.394 487.569 256.002 487.571C237.611 487.574 219.894 480.644 206.385 468.163L206.178 467.98C196.184 458.571 186.48 448.859 177.079 438.857H112.591C110.327 438.856 108.108 439.486 106.183 440.677C104.257 441.868 102.702 443.572 101.693 445.599L77.312 494.361C76.3836 496.219 75.9453 498.283 76.0387 500.358C76.1322 502.433 76.7543 504.45 77.8461 506.217C78.9378 507.984 80.463 509.443 82.2769 510.455C84.0908 511.467 86.1332 511.999 88.2103 512H423.79C425.867 511.999 427.909 511.467 429.723 510.455C431.537 509.443 433.062 507.984 434.154 506.217C435.246 504.45 435.868 502.433 435.961 500.358C436.055 498.283 435.617 496.219 434.688 494.361L410.307 445.599C409.298 443.572 407.743 441.868 405.818 440.677C403.892 439.486 401.673 438.856 399.409 438.857H334.909Z"
                    fill="#C5DCF1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M256 0C356.925 0 438.857 81.9322 438.857 182.857C438.857 295.01 337.993 404.37 289.133 450.182L289.072 450.243C280.071 458.566 268.262 463.19 256.002 463.192C243.742 463.195 231.932 458.575 222.927 450.255L222.866 450.194C174.007 404.37 73.1426 295.01 73.1426 182.857C73.1426 81.9322 155.075 0 256 0ZM256 121.905C222.354 121.905 195.047 149.211 195.047 182.857C195.047 216.503 222.354 243.81 256 243.81C289.645 243.81 316.952 216.503 316.952 182.857C316.952 149.211 289.645 121.905 256 121.905Z"
                    fill="#C5DCF1"
                />
                <path
                    d="M305.996 301.921V260.578C305.993 258.047 303.939 255.997 301.407 256C300.49 256.001 299.595 256.277 298.836 256.792L198 325.544C195.909 326.971 195.371 329.823 196.798 331.914C197.12 332.386 197.528 332.794 198 333.116L298.836 401.868C300.93 403.29 303.781 402.746 305.203 400.652C305.718 399.894 305.994 398.998 305.996 398.081V356.968C358.128 359.975 406.318 413.078 406.832 425.39V425.656C406.871 428.158 408.912 430.166 411.415 430.166C413.946 430.166 415.999 428.113 415.999 425.582V425.353C415.082 374.596 376.49 304.836 305.996 301.921Z"
                    fill="#1975C9"
                />
            </svg>



        </>
    )
}

export default RTOAddressIcon