import React from 'react'

const DeleteIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.55891 4.71674L2.91016 6.37424L3.09166 7.10174L4.88866 6.65399L5.49016 19.2855C5.5314 20.1518 5.9046 20.9691 6.53231 21.5676C7.16003 22.1661 7.99407 22.5 8.86141 22.5H15.1404C16.0077 22.5 16.8418 22.1661 17.4695 21.5676C18.0972 20.9691 18.4704 20.1518 18.5117 19.2855L19.1252 6.39299C19.1277 6.34228 19.1199 6.2916 19.1022 6.244C19.0846 6.19641 19.0574 6.15291 19.0224 6.11615C18.9874 6.07939 18.9453 6.05013 18.8986 6.03016C18.8519 6.01019 18.8017 5.99992 18.7509 5.99999H7.51216L20.3417 2.80124L20.1602 2.07374L15.2492 3.29774L14.5629 1.84724C14.5258 1.76892 14.4627 1.70587 14.3844 1.66881C14.306 1.63176 14.2172 1.62299 14.1332 1.64399L9.76741 2.73224C9.68317 2.75315 9.60879 2.80262 9.55696 2.87224C9.50512 2.94185 9.47904 3.02729 9.48316 3.11399L9.55891 4.71674ZM8.62591 8.99999V18.75H9.37591V8.99999H8.62591ZM11.6259 8.99999V18.75H12.3759V8.99999H11.6259ZM14.6259 8.99999V18.75H15.3759V8.99999H14.6259ZM11.8967 4.13399L10.3007 4.53149L10.2467 3.38549L14.0169 2.44574L14.5074 3.48299L11.8967 4.13399Z"
                    fill="#1975C9"
                />
            </svg>


        </>
    )
}

export default DeleteIcon