import React from 'react'

const ReferEarnIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <circle
                        cx={256}
                        cy={256}
                        r={256}
                        fill="#c5dcf1"
                        opacity={1}
                        data-original="#ffce00"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M300.456 508.154c97.803-17.125 176.508-89.763 202.437-184.238L356.767 177.789 336.4 200.955 216.055 80.61l-72.228 65.106 84.115 82.892-141.807 31.46 82.267 82.267-10.652 10.121 3.163 3.445 43.964 43.681-6.9 6.092z"
                        opacity={1}
                        data-original="#ffa300"
                        className=""
                    />
                    <circle
                        cx="178.91"
                        cy="112.012"
                        r="48.65"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#7e8596"
                        className=""
                    />
                    <path
                        fill="#1975c9"
                        d="M227.56 112.012c0 26.869-21.781 48.65-48.65 48.65v-97.3c26.868 0 48.65 21.782 48.65 48.65z"
                        opacity={1}
                        data-original="#636978"
                        className="hovered-path"
                    />
                    <path
                        fill="#1975c9"
                        d="M86.135 260.068c0-51.238 41.536-92.774 92.774-92.774s92.774 41.536 92.774 92.774z"
                        opacity={1}
                        data-original="#7e8596"
                        className=""
                    />
                    <path
                        fill="#1975c9"
                        d="M178.91 260.068v-92.774c51.238 0 92.774 41.536 92.774 92.774z"
                        opacity={1}
                        data-original="#636978"
                        className="hovered-path"
                    />
                    <circle
                        cx="313.836"
                        cy="220.779"
                        r="60.756"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#636978"
                        className="hovered-path"
                    />
                    <path
                        fill="#1975c9"
                        d="M313.836 160.023v121.511c33.554 0 60.755-27.201 60.755-60.756s-27.2-60.755-60.755-60.755z"
                        opacity={1}
                        data-original="#414952"
                        className=""
                    />
                    <path
                        fill="#1975c9"
                        d="M197.977 405.674c0-63.987 51.872-115.859 115.859-115.859s115.859 51.872 115.859 115.859z"
                        opacity={1}
                        data-original="#636978"
                        className="hovered-path"
                    />
                    <path
                        fill="#1975c9"
                        d="M313.836 289.815v115.859h115.859c.001-63.987-51.871-115.859-115.859-115.859z"
                        opacity={1}
                        data-original="#414952"
                        className=""
                    />
                    <path
                        fill="#ffffff"
                        d="M205.001 329.803v-53.458h33.04l-62.369-70.697-62.369 70.696h32.077V318.5c0 22.645 14.443 42.768 35.897 50.015l85.626 28.924z"
                        opacity={1}
                        data-original="#ffffff"
                    />
                </g>
            </svg>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={28}
                height={28}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <circle
                        cx={256}
                        cy={256}
                        r={256}
                        fill="#1975c9"
                        opacity={1}
                        data-original="#ffce00"
                        className="hovered-path"
                    />
                    <path
                        fill="#c5dcf1"
                        d="M300.456 508.154c97.803-17.125 176.508-89.763 202.437-184.238L356.767 177.789 336.4 200.955 216.055 80.61l-72.228 65.106 84.115 82.892-141.807 31.46 82.267 82.267-10.652 10.121 3.163 3.445 43.964 43.681-6.9 6.092z"
                        opacity={1}
                        data-original="#ffa300"
                        className=""
                    />
                    <circle
                        cx="178.91"
                        cy="112.012"
                        r="48.65"
                        fill="#60a9eb"
                        opacity={1}
                        data-original="#7e8596"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M227.56 112.012c0 26.869-21.781 48.65-48.65 48.65v-97.3c26.868 0 48.65 21.782 48.65 48.65z"
                        opacity={1}
                        data-original="#636978"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M86.135 260.068c0-51.238 41.536-92.774 92.774-92.774s92.774 41.536 92.774 92.774z"
                        opacity={1}
                        data-original="#7e8596"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M178.91 260.068v-92.774c51.238 0 92.774 41.536 92.774 92.774z"
                        opacity={1}
                        data-original="#636978"
                        className=""
                    />
                    <circle
                        cx="313.836"
                        cy="220.779"
                        r="60.756"
                        fill="#60a9eb"
                        opacity={1}
                        data-original="#636978"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M313.836 160.023v121.511c33.554 0 60.755-27.201 60.755-60.756s-27.2-60.755-60.755-60.755z"
                        opacity={1}
                        data-original="#414952"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M197.977 405.674c0-63.987 51.872-115.859 115.859-115.859s115.859 51.872 115.859 115.859z"
                        opacity={1}
                        data-original="#636978"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M313.836 289.815v115.859h115.859c.001-63.987-51.871-115.859-115.859-115.859z"
                        opacity={1}
                        data-original="#414952"
                        className=""
                    />
                    <path
                        fill="#ffffff"
                        d="M205.001 329.803v-53.458h33.04l-62.369-70.697-62.369 70.696h32.077V318.5c0 22.645 14.443 42.768 35.897 50.015l85.626 28.924z"
                        opacity={1}
                        data-original="#ffffff"
                        className=""
                    />
                </g>
            </svg> */}

        </>
    )
}

export default ReferEarnIcon