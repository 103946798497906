export const GET_BILLING_DATA="GET_BILLING_DATA"
export const GET_BILLING_SHIPING_DATA="GET_BILLING_SHIPING_DATA"
export const GET_BILLING_SHIPING_REMITANCE_DATA="GET_BILLING_SHIPING_REMITANCE_DATA"
export const GET_BILLING_SHIPING_RECHARGE_DATA="GET_BILLING_SHIPING_RECHARGE_DATA"
export const GET_BILLING_SHIPING_INVOICE_DATA="GET_BILLING_SHIPING_INVOICE_DATA"
export const GET_BILLING_SHIPING_RECEIPT_DATA="GET_BILLING_SHIPING_RECEIPT_DATA"
export const GET_BILLING_SHIPING_RECEIPT_EXPORT_DATA="GET_BILLING_SHIPING_RECEIPT_EXPORT_DATA"
export const GET_BILLING_SHIPING_REMITANCE_DOWNLOAD_DATA="GET_BILLING_SHIPING_REMITANCE_DOWNLOAD_DATA"
export const GET_BILLING_SHIPING_INVOICE_DOWNLOAD_DATA="GET_BILLING_SHIPING_INVOICE_DOWNLOAD_DATA"

export const GET_BILLING_PASSBOOK_COUNTER_DATA="GET_BILLING_PASSBOOK_COUNTER_DATA"
export const GET_BILLING_RECHARGE_COUNTER_DATA="GET_BILLING_RECHARGE_COUNTER_DATA"
export const GET_BILLING_SHIPPING_COUNTER_DATA="GET_BILLING_SHIPPING_COUNTER_DATA"
export const GET_BILLING_REMITANCE_EXPORT_DATA="GET_BILLING_REMITANCE_EXPORT_DATA"

export const GET_BILLING_INVOICE_DOWNLOAD_DATA="GET_BILLING_INVOICE_DOWNLOAD_DATA"
export const GET_BILLING_SELLER_DATA="GET_BILLING_SELLER_DATA"