import React from 'react'

const OTPIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={80}
                height={80}
                x={0}
                y={0}
                viewBox="0 0 34 34"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        fill="#60a9eb"
                        d="M25.3 5.7c.4-.1.8.3.8.7v5.8c0 5.5-3.8 10.3-9.2 11.6-5.4-1.3-9.2-6.1-9.2-11.6V6.4c0-.4.4-.8.8-.7s.8.1 1.2.1c2 0 4-.8 5.4-2.2l1.2-1.2c.3-.3.7-.3 1 0l1.2 1.2c1.9 1.7 4.4 2.5 6.8 2.1z"
                        opacity={1}
                        data-original="#99e699"
                        className=""
                    />
                    <path
                        fill="#ffffff"
                        d="M15.7 16.2c-.3 0-.5-.1-.7-.3l-2.2-2.2c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l1.5 1.5 4-4c.4-.4 1-.4 1.4 0s.4 1 0 1.4L16.3 16c-.1.1-.3.2-.6.2z"
                        opacity={1}
                        data-original="#ffffff"
                        className=""
                    />
                    <path
                        fill="#60a9eb"
                        d="M27.5 31.8h-21c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h21c1.1 0 2 .9 2 2v4c0 1.2-.9 2-2 2z"
                        opacity={1}
                        data-original="#ff9999"
                        className=""
                    />
                    <g fill="#fff">
                        <circle
                            cx="14.5"
                            cy="27.8"
                            r="1.3"
                            fill="#ffffff"
                            opacity={1}
                            data-original="#ffffff"
                            className=""
                        />
                        <circle
                            cx="9.5"
                            cy="27.8"
                            r="1.3"
                            fill="#ffffff"
                            opacity={1}
                            data-original="#ffffff"
                            className=""
                        />
                        <circle
                            cx="24.5"
                            cy="27.8"
                            r="1.3"
                            fill="#ffffff"
                            opacity={1}
                            data-original="#ffffff"
                            className=""
                        />
                        <circle
                            cx="19.5"
                            cy="27.8"
                            r="1.3"
                            fill="#ffffff"
                            opacity={1}
                            data-original="#ffffff"
                            className=""
                        />
                    </g>
                </g>
            </svg>

        </>
    )
}

export default OTPIcon