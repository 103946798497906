import React from 'react'

const ActionRequestedIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 510.492 510.492"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M263.712 117.879v183.608M205.581 268.877v60.966M322.551 301.487v-92.513c0-16.248-13.172-29.42-29.42-29.42s-29.42 13.172-29.42 29.42v92.513M381.391 301.487v-92.513c0-16.248-13.172-29.42-29.42-29.42s-29.42 13.172-29.42 29.42v92.513"
                        style={{ strokeWidth: 30, strokeMiterlimit: 10 }}
                        fill="none"
                        stroke="#1975c9"
                        strokeWidth={30}
                        strokeMiterlimit={10}
                        data-original="#000000"
                        opacity={1}
                        className=""
                    />
                    <path
                        d="M263.712 117.879c0-16.052-13.013-29.065-29.065-29.065s-29.065 13.013-29.065 29.065v150.998c0-30.539-24.756-55.295-55.295-55.295h-2.836v135.52c0 80.849 65.541 146.39 146.39 146.39 80.849 0 146.39-65.541 146.39-146.39V234.495c0-16.248-13.172-29.42-29.42-29.42s-29.42 13.172-29.42 29.42v66.992"
                        style={{ strokeWidth: 30, strokeMiterlimit: 10 }}
                        fill="none"
                        stroke="#1975c9"
                        strokeWidth={30}
                        strokeMiterlimit={10}
                        data-original="#000000"
                        opacity={1}
                        className=""
                    />
                    <path
                        d="M150.952 165.27c-9.318-15.045-14.697-32.787-14.697-51.784 0-54.392 44.094-98.486 98.486-98.486s98.486 44.094 98.486 98.486a98.59 98.59 0 0 1-3.463 25.981M366.228 14.656c20.713 27.514 32.991 61.738 32.991 98.83M85.474 182.665c-9.763-21.03-15.212-44.468-15.212-69.179 0-28.595 7.297-55.487 20.131-78.913"
                        style={{ strokeWidth: 30, strokeMiterlimit: 10 }}
                        fill="none"
                        stroke="#1975c9"
                        strokeWidth={30}
                        strokeMiterlimit={10}
                        data-original="#000000"
                        opacity={1}
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default ActionRequestedIcon