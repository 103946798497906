import React from 'react'

const PackageMagnifyingIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={40}
                height={40}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        fillRule="evenodd"
                        d="M324.546 192.192c55.608 0 100.688 45.079 100.688 100.687 0 55.606-45.079 100.684-100.688 100.684-55.604 0-100.686-45.078-100.686-100.684 0-55.608 45.082-100.687 100.686-100.687m-83.697.795c55.169-46.223 137.363-38.974 183.587 16.195 46.227 55.168 38.979 137.364-16.19 183.586-55.168 46.225-137.363 38.974-183.587-16.191-46.225-55.17-38.978-137.365 16.19-183.59zm176.516 238.402 4.487-3.758-12.386-14.779a147.694 147.694 0 0 0 9.479-7.307 149.452 149.452 0 0 0 8.856-8.054l12.382 14.78 4.485-3.758 57.191 68.255c6.29 7.512 5.295 18.803-2.215 25.093-7.507 6.29-18.796 5.293-25.085-2.212zm-66.356-203.872c-4.265-1.724-6.317-6.578-4.594-10.838a8.33 8.33 0 0 1 10.841-4.59 87.428 87.428 0 0 1 20.162 11.532 87.4 87.4 0 0 1 16.417 16.421c2.779 3.648 2.085 8.867-1.568 11.652-3.645 2.788-8.866 2.087-11.652-1.562a70.766 70.766 0 0 0-13.287-13.295 70.702 70.702 0 0 0-16.319-9.32zM205.991 6.002l66.779 38.551L72.779 160.02v54.971l66.094 38.161v-54.969L338.866 82.715l67.114 38.752v49.014c-20.258-13.496-43.731-21.833-68.519-24.019-38.92-3.435-77.361 8.651-107.313 33.751-29.973 25.111-48.583 60.805-52.021 99.748-3.435 38.925 8.654 77.36 33.756 107.316 16.157 19.29 36.706 33.87 59.677 42.729l-65.57 37.86L6.001 352.397V121.468z"
                        clipRule="evenodd"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                </g>
            </svg>
        </>
    )
}

export default PackageMagnifyingIcon