import React from 'react'

const ReverseOrdersIcon = () => {
    return (
        <>
            <svg
                width={32}
                height={32}
                viewBox="0 0 535 462"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.882 462C237.755 462 236.629 461.793 235.541 461.379L5.87356 372.121C4.14439 371.447 2.65878 370.267 1.6109 368.736C0.563011 367.205 0.00158792 365.394 0 363.539V98.4663C0.000162399 96.611 0.56102 94.799 1.60909 93.2677C2.65716 91.7364 4.14357 90.5572 5.87356 89.8847L235.541 0.626557C237.69 -0.208852 240.075 -0.208852 242.224 0.626557L471.903 89.8858C473.633 90.5586 475.119 91.7379 476.167 93.2691C477.215 94.8003 477.776 96.6122 477.776 98.4675V253.079H459.354V111.919L248.093 194.025V439.342L362.204 394.998L368.88 412.156L242.224 461.38C241.158 461.79 240.025 462 238.882 462ZM229.674 194.028L172.066 171.637V221.916C172.066 226.68 169.268 231.049 164.939 233.048C163.332 233.786 161.585 234.167 159.817 234.167C156.948 234.167 154.106 233.164 151.853 231.243L120.262 204.346L91.9393 213.147C88.2358 214.299 84.1443 213.62 81.0291 211.322C79.4805 210.182 78.2214 208.694 77.3532 206.979C76.485 205.264 76.0319 203.368 76.0305 201.446V134.311L18.4219 111.92V357.242L229.674 439.345V194.028ZM443.149 98.4652L392.521 78.7911C324.434 105.255 256.348 131.719 188.266 158.178L238.882 177.849L443.149 98.4652ZM85.2426 118.138L289.491 38.7515L238.882 19.0843L34.6266 98.4652L85.2426 118.138ZM153.644 164.478L94.4501 141.47V193.084L119.626 185.26C121.109 184.798 122.685 184.72 124.206 185.031C125.728 185.342 127.146 186.033 128.328 187.04L153.644 208.585V164.478ZM110.65 128.013L162.849 148.3C230.934 121.847 299.024 95.3814 367.109 68.9161L314.901 48.6265L110.65 128.013Z"
                    fill="#F31429"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M523.191 381.713C533.189 362.525 536.788 340.643 533.464 319.265C530.139 297.886 520.065 278.13 504.712 262.885C489.36 247.64 469.535 237.704 448.133 234.528C426.732 231.353 404.876 235.106 385.759 245.237C366.641 255.369 351.265 271.348 341.877 290.841C332.489 310.334 329.58 332.318 333.577 353.582C337.573 374.845 348.265 394.274 364.09 409.028C379.915 423.782 400.043 433.089 421.534 435.588C424.66 435.805 427.749 434.809 430.16 432.808C432.571 430.807 434.119 427.954 434.481 424.842C434.844 421.73 433.994 418.597 432.108 416.095C430.222 413.593 427.445 411.914 424.353 411.406C413.262 410.131 402.582 406.458 393.054 400.641C383.526 394.824 375.378 387.003 369.176 377.721C345.522 342.364 355.024 294.455 390.38 270.801C404.609 261.298 421.596 256.798 438.663 258.011C455.731 259.225 471.91 266.081 484.652 277.502C497.394 288.922 505.975 304.257 509.042 321.09C512.11 337.924 509.49 355.299 501.596 370.48C500.858 371.898 500.407 373.448 500.268 375.04C500.13 376.632 500.306 378.236 500.787 379.76C501.268 381.285 502.045 382.699 503.073 383.923C504.1 385.147 505.359 386.157 506.777 386.894C508.195 387.632 509.745 388.083 511.337 388.222C512.929 388.36 514.533 388.184 516.057 387.703C517.582 387.222 518.996 386.445 520.22 385.417C521.444 384.389 522.454 383.131 523.191 381.713Z"
                    fill="#F31429"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M402.017 458.264L440.162 432.73C441.49 431.841 442.631 430.7 443.518 429.37C444.405 428.041 445.022 426.55 445.333 424.982C445.644 423.415 445.644 421.801 445.331 420.234C445.018 418.666 444.4 417.176 443.512 415.847L417.977 377.703C416.173 375.04 413.388 373.201 410.231 372.585C407.074 371.97 403.802 372.629 401.13 374.419C398.458 376.209 396.603 378.984 395.97 382.137C395.338 385.291 395.979 388.567 397.754 391.248L416.513 419.264L388.485 438.032C385.802 439.827 383.941 442.613 383.313 445.779C382.685 448.946 383.34 452.231 385.135 454.914C386.929 457.597 389.716 459.458 392.882 460.086C396.048 460.714 399.334 460.059 402.017 458.264Z"
                    fill="#F31429"
                />
            </svg>


        </>
    )
}

export default ReverseOrdersIcon