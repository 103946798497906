import React from 'react'

const OrdersIcon = () => {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        viewBox="0 -960 960 960"
        width={24}
      >
        <path d="M160-160v-516L82-846l72-34 94 202h464l94-202 72 34-78 170v516H160Zm240-280h160q17 0 28.5-11.5T600-480q0-17-11.5-28.5T560-520H400q-17 0-28.5 11.5T360-480q0 17 11.5 28.5T400-440ZM240-240h480v-358H240v358Zm0 0v-358 358Z" fill="#0F3C5B" />
      </svg> */}
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        x={0}
        y={0}
        viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className=""
      >
        <g>
          <path
            d="M458.732 422.212 435.87 134.103c-1.419-18.563-17.124-33.098-35.737-33.098h-45.164v66.917c0 8.287-6.708 14.995-14.995 14.995-8.277 0-14.995-6.708-14.995-14.995v-66.917H187.028v66.917c0 8.287-6.718 14.995-14.995 14.995-8.287 0-14.995-6.708-14.995-14.995v-66.917h-45.164c-18.613 0-34.318 14.535-35.737 33.058L53.265 422.252c-1.769 23.082 6.238 46.054 21.962 63.028C90.952 502.253 113.244 512 136.386 512h239.236c23.142 0 45.434-9.747 61.159-26.721 15.724-16.974 23.731-39.946 21.951-63.067zM323.56 275.493l-77.553 77.553a14.958 14.958 0 0 1-10.606 4.398 14.956 14.956 0 0 1-10.606-4.398l-36.347-36.347c-5.858-5.858-5.858-15.345 0-21.203 5.858-5.858 15.355-5.858 21.203 0l25.751 25.741 66.956-66.956c5.848-5.848 15.345-5.848 21.203 0 5.857 5.858 5.857 15.354-.001 21.212zM256.004 0c-54.571 0-98.965 44.404-98.965 98.975v2.029h29.99v-2.029c0-38.037 30.939-68.986 68.976-68.986s68.976 30.949 68.976 68.986v2.029h29.989v-2.029C354.969 44.404 310.575 0 256.004 0z"
            fill="#1975C9"
            opacity={1}
            data-original="#1975C9"
            className=""
          />
        </g>
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        x={0}
        y={0}
        viewBox="0 0 24 24"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className=""
      >
        <g>
          <path
            d="M1 1a1 1 0 1 0 0 2h1.78a.694.694 35.784 0 1 .657.474l3.297 9.893c.147.44.165.912.053 1.362l-.271 1.087C6.117 17.41 7.358 19 9 19h12a1 1 0 1 0 0-2H9c-.39 0-.64-.32-.545-.697l.205-.818A.64.64 142.028 0 1 9.28 15H20a1 1 0 0 0 .95-.684l2.665-8A1 1 0 0 0 22.666 5H6.555a.694.694 35.783 0 1-.658-.474l-.948-2.842A1 1 0 0 0 4 1zm7 19a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
            paintOrder="fill markers stroke"
            fill="#000000"
            opacity={1}
            data-original="#000000"
            className=""
          />
        </g>
      </svg>

    </>


  )
}

export default OrdersIcon