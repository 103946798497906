import React from 'react'

const HeartIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={40}
                height={40}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        fill="#1975c9"
                        d="M509 135.3c-7.3-34.1-25.1-61.9-53.2-82.6-30.4-22.4-64.5-31.4-102.1-26-26.9 3.9-49.9 16.4-71 32.8-9.3 7.2-18 15.2-27.2 23-.4-.3-21.5-19.4-32.4-27.4-22.7-16.8-47.8-28.3-76.4-29.8C112.9 23.6 82 32.8 55 53.6 18.8 81.4.3 118.7 0 164.2c-.3 44 12.5 84.9 33.4 123.3 19 35 43.3 66.1 71.5 94.2C143 420 186 451.8 232 479.8c16.5 10.1 33.5 9 49.9-1.1 26.2-16.1 51.5-33.7 75.6-52.8 41-32.7 77.7-69.5 106.4-113.7 21.5-33.3 37.5-68.9 44.4-108.2 4-22.9 5.5-45.9.7-68.7zm-116.7 72.2L244.5 355.3c-15.6 15.7-41 15.7-56.7 0l-68.1-68.1c-15.7-15.7-15.7-41 0-56.7 15.7-15.7 41-15.7 56.7 0l39.8 39.8 119.4-119.4c15.7-15.7 41-15.7 56.7 0 15.7 15.6 15.7 40.9 0 56.6z"
                        opacity={1}
                        data-original="#ff2147"
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default HeartIcon