import React from 'react'

const WeightRecordsIcon = () => {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        viewBox="0 -960 960 960"
        width={24}
      >
        <path d="M80-80q0-111 29.5-189.5T185-400q46-52 103-80.5T400-520v-120q-137-17-228.5-84.5T80-880h800q0 88-91.5 155.5T560-640v120q55 11 112 39.5T775-400q46 52 75.5 130.5T880-80H640v-80h155q-18-152-113.5-220T480-448q-106 0-201.5 68T165-160h155v80H80Zm400-635q91 0 162-24.5T755-800H205q42 36 113 60.5T480-715Zm0 635q-33 0-56.5-23.5T400-160q0-17 6.5-31t17.5-25q24-24 81-50.5T640-320q-28 78-54 135t-50 81q-11 11-25 17.5T480-80Zm0-635Z" fill="#0F3C5B" />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        x={0}
        y={0}
        viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className=""
      >
        <g>
          <path
            d="M400.987 90.342c30.772-16.21 55.752-39.487 72.238-67.314A15.253 15.253 0 0 0 460.101 0H51.898a15.253 15.253 0 0 0-13.123 23.027c16.486 27.827 41.466 51.105 72.238 67.314 26.424 13.92 56.216 22.137 86.911 24.073V153.6h-72.087c-26.953 0-49.748 20.174-53.024 46.926L42.009 452.087a53.448 53.448 0 0 0 13.021 41.896A53.45 53.45 0 0 0 95.033 512h321.942a53.452 53.452 0 0 0 40.003-18.016 53.448 53.448 0 0 0 13.021-41.896l-30.803-251.56c-3.276-26.753-26.071-46.926-53.024-46.926h-72.096v-39.186c30.694-1.938 60.487-10.155 86.911-24.074zm-19.986 242.457c0 68.924-56.073 124.998-124.997 124.998s-124.997-56.074-124.997-124.998c0-68.923 56.074-124.997 124.997-124.997s124.997 56.074 124.997 124.997z"
            fill="#000000"
            opacity={1}
            data-original="#000000"
            className=""
          />
          <path
            d="M256.004 238.308c-52.103 0-94.491 42.389-94.491 94.491 0 52.103 42.389 94.492 94.491 94.492s94.491-42.389 94.491-94.492c0-52.102-42.389-94.491-94.491-94.491zm10.786 105.277-36.724 36.724c-2.978 2.978-6.882 4.468-10.785 4.468s-7.807-1.49-10.785-4.468c-5.957-5.956-5.957-15.614 0-21.57l36.724-36.724c5.956-5.956 15.614-5.956 21.57 0 5.956 5.955 5.956 15.613 0 21.57z"
            fill="#000000"
            opacity={1}
            data-original="#000000"
            className=""
          />
        </g>
      </svg>


    </>

  )
}

export default WeightRecordsIcon