import React from 'react'

const NdrIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M414 28.055h-56.325C342.812 10.889 320.908 0 296.5 0h-81c-24.408 0-46.312 10.889-61.175 28.055H98c-24.813 0-45 20.227-45 45.088v393.769C53 491.773 73.187 512 98 512h316c24.813 0 45-20.227 45-45.088V73.143c0-24.862-20.187-45.088-45-45.088zm-198.5 2.004h81c23.177 0 42.425 15.515 48.75 36.07h-178.5c6.314-20.519 25.537-36.07 48.75-36.07zM429 466.912c0 8.287-6.729 15.029-15 15.029H98c-8.271 0-15-6.742-15-15.029V73.143c0-8.287 6.729-15.029 15-15.029h39.846a81.017 81.017 0 0 0-3.346 23.045c0 8.3 6.716 15.029 15 15.029h213c8.284 0 15-6.729 15-15.029a81.017 81.017 0 0 0-3.346-23.045H414c8.271 0 15 6.742 15 15.029v393.769z"
                        fill="#3BB54B"
                        opacity={1}
                        data-original="#3BB54B"
                        className=""
                    />
                    <path
                        d="M182.06 298.644 160 320.746l-5.394-5.404c-5.857-5.87-15.355-5.87-21.213 0-5.858 5.869-5.858 15.385 0 21.254l16 16.031c5.857 5.869 15.355 5.87 21.213 0l32.666-32.73c5.858-5.869 5.858-15.385 0-21.254-5.857-5.869-15.355-5.869-21.212.001zM367 310.607H246c-8.284 0-15 6.729-15 15.029s6.716 15.029 15 15.029h121c8.284 0 15-6.729 15-15.029s-6.716-15.029-15-15.029zM182.06 384.812 160 406.914l-5.394-5.404c-5.857-5.87-15.355-5.87-21.213 0-5.858 5.869-5.858 15.385 0 21.254l16 16.031c5.857 5.869 15.355 5.87 21.213 0l32.666-32.73c5.858-5.869 5.858-15.385 0-21.254-5.857-5.869-15.355-5.869-21.212.001zM367 396.775H246c-8.284 0-15 6.729-15 15.029s6.716 15.029 15 15.029h121c8.284 0 15-6.729 15-15.029s-6.716-15.029-15-15.029zM284.606 241.077 331 194.593v1.791c0 8.3 6.716 15.029 15 15.029s15-6.729 15-15.029c0-42.513.07-38.555-.172-40.234-1.061-7.353-7.353-12.872-14.833-12.87H308c-8.284 0-15 6.729-15 15.029s6.716 15.029 15 15.029h1.787L274 209.195l-45.394-45.482c-5.857-5.87-15.355-5.87-21.213 0l-54 54.106c-5.858 5.869-5.858 15.385 0 21.254 5.857 5.87 15.355 5.87 21.213 0L218 195.595l45.394 45.482c5.856 5.869 15.355 5.87 21.212 0z"
                        fill="#3BB54B"
                        opacity={1}
                        data-original="#3BB54B"
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default NdrIcon