import { GET_EXPORT_DATA,GET_EXPORT_PASSBOOK_DATA,GET_EXPORT_SHIPPING_DATA,GET_EXPORT_RECHARGE_DATA,GET_EXPORT_INVOICE_DATA,GET_EXPORT_WEIGHT_DATA,GET_EXPORT_REMITANCE_DATA,GET_EXPORT_RECEIPT_DATA,GET_EXPORT_ALL_DATA,GET_EXPORT_SHIPMENT_DATA,GET_EXPORT_SHIPMENT_ALL_DATA } from "../../constants/exports";

const initialState = {
    exportCard:[],
    exportAllCard:[],
    exportPassbookCard:[],
    exportShippingCard:[],
    exportRechargeCard:[],
    exportInvoiceCard:[],
    exportWeightCard:[],
    exportRemitanceCard:[],
    exportReceiptCard:[],
    exportShipmentAllCard:[],
    exportShipmentCard:[]
};

export const exportSectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXPORT_DATA:
            return {
                ...state,
                exportCard: action?.payload
            };
        case GET_EXPORT_PASSBOOK_DATA:
            return {
                ...state,
                exportPassbookCard: action?.payload
            };
        case GET_EXPORT_SHIPPING_DATA:
            return {
                ...state,
                exportShippingCard: action?.payload
            };
        case GET_EXPORT_RECHARGE_DATA:
            return {
                ...state,
                exportRechargeCard: action?.payload
            };
        case GET_EXPORT_INVOICE_DATA:
            return {
                ...state,
                exportInvoiceCard: action?.payload
            };
        case GET_EXPORT_WEIGHT_DATA:
            return {
                ...state,
                exportWeightCard: action?.payload
            };
        case GET_EXPORT_REMITANCE_DATA:
            return {
                ...state,
                exportRemitanceCard: action?.payload
            };
        case GET_EXPORT_RECEIPT_DATA:
            return {
                ...state,
                exportReceiptCard: action?.payload
            };
        case GET_EXPORT_ALL_DATA:
            return {
                ...state,
                exportAllCard: action?.payload
            };
        case GET_EXPORT_SHIPMENT_ALL_DATA:
            return {
                ...state,
                exportShipmentAllCard: action?.payload
            };
        case GET_EXPORT_SHIPMENT_DATA:
            return {
                ...state,
                exportShipmentCard: action?.payload
            };
        default:
            return state
    }
}