import React from 'react'

const BillingIcon = () => {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        viewBox="0 -960 960 960"
        width={24}
      >
        <path d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z" fill="#0F3C5B" />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        x={0}
        y={0}
        viewBox="0 0 24 24"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className=""
      >
        <g>
          <path
            d="M19 1H5a1 1 0 0 0-.65.25A4 4 0 0 1 7 4.6 1.75 1.75 0 0 1 7 5v17a1 1 0 0 0 .58.91 1 1 0 0 0 1.07-.15l2.85-2.44 2.85 2.44a1 1 0 0 0 1.3 0l2.85-2.44 2.85 2.44A1 1 0 0 0 22 23a1.06 1.06 0 0 0 .42-.09A1 1 0 0 0 23 22V5a4 4 0 0 0-4-4zm-1 14h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm0-4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm0-4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM5 5v8H2a1 1 0 0 1-1-1V4.6A2 2 0 0 1 5 5z"
            fill="#000000"
            opacity={1}
            data-original="#000000"
            className=""
          />
        </g>
      </svg>
    </>
  )
}

export default BillingIcon