export const ORDERS_GET_ACTION="ORDERS_GET_ACTION"
export const ORDERS_DETAILS_GET_ACTION="ORDERS_DETAILS_GET_ACTION"
export const ORDERS_DETAILS_CANCEL_ACTION="ORDERS_DETAILS_CANCEL_ACTION"
export const ORDERS_DETAILS_UPDATE_ACTION="ORDERS_DETAILS_UPDATE_ACTION"
export const CLONE_ORDERS_UPDATE_ACTION="CLONE_ORDERS_UPDATE_ACTION"
export const DELETE_ORDERS_ACTION="DELETE_ORDERS_ACTION"
export const GET_SAVE_FAVOURITE_ORDERS_ACTION="GET_SAVE_FAVOURITE_ORDERS_ACTION"
export const SAVE_FAVOURITE_ORDERS_ACTION="SAVE_FAVOURITE_ORDERS_ACTION"
export const BULK_SHIP_ORDERS_ACTION="BULK_SHIP_ORDERS_ACTION"

export const GENERATE_MANIFEST_ACTION="GENERATE_MANIFEST_ACTION"

export const MANIFEST_LIST_API_ACTION="MANIFEST_LIST_API_ACTION"
export const ORDERS_TAG_LIST_API_ACTION="ORDERS_TAG_LIST_API_ACTION"
export const ORDERS_DETAILS_CLONE_ACTION="ORDERS_DETAILS_CLONE_ACTION"
export const CREATE_ORDERS_TAG_ACTION="CREATE_ORDERS_TAG_ACTION"
export const GET_ORDER_SOURCE_API_ACTION = "GET_ORDER_SOURCE_API_ACTION"
export const GET_ORDER_DATA_ACTION="GET_ORDER_DATA_ACTION"



