export const BULK_ADD_ORDER_TAG_ACTION="BULK_ADD_ORDER_TAG_ACTION"
export const BULK_MARK_ORDER_VERIFY_ACTION="BULK_MARK_ORDER_VERIFY_ACTION"
export const BULK_DELETE_ORDER_ACTION="BULK_DELETE_ORDER_ACTION"
export const BULK_CANCEL_ORDER_ACTION="BULK_CANCEL_ORDER_ACTION"
export const BULK_PICKUP_ADDRESS_UPDATE_ACTION="BULK_PICKUP_ADDRESS_UPDATE_ACTION"
export const BULK_DIMESION_DETAILS_UPDATE_ACTION="BULK_DIMESION_DETAILS_UPDATE_ACTION"
export const BULK_GENERATE_MENIFEST_ACTION="BULK_GENERATE_MENIFEST_ACTION"
export const BULK_ORDER_GENERATE_PICKUP_ACTION="BULK_ORDER_GENERATE_PICKUP_ACTION"
export const BULK_ORDER_GENERATE_LABEL_ACTION="BULK_ORDER_GENERATE_LABEL_ACTION"
export const BULK_ORDER_GENERATE_INVOICE_ACTION="BULK_ORDER_GENERATE_INVOICE_ACTION"
export const BULK_ORDER_GENERATE_MANIFEST_ACTION="BULK_ORDER_GENERATE_MANIFEST_ACTION"
export const BULK_ORDER_DOWNLOAD_MANIFEST_ACTION="BULK_ORDER_DOWNLOAD_MANIFEST_ACTION"
export const BULK_GET_DIMENSION_DETAILS_ACTION="BULK_GET_DIMENSION_DETAILS_ACTION"
export const BULK_PROCESSING_ORDER_CANCEL_ACTION="BULK_PROCESSING_ORDER_CANCEL_ACTION"

