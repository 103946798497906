import React from 'react'

const VerifiedIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M23.9525 15.3779C23.8525 15.7247 23.6324 16.0048 23.319 16.1848L21.1716 17.3852V19.8393C21.1716 20.5729 20.5714 21.1731 19.8378 21.1731H17.377L16.1833 23.3205C16.0032 23.6339 15.7232 23.854 15.3764 23.954C15.0363 24.0474 14.6762 24.0073 14.3627 23.834L12.002 22.5135L9.64119 23.834C9.43445 23.9473 9.21438 24.0007 8.98764 24.0007C8.8676 24.0007 8.74756 23.9873 8.62753 23.954C8.28075 23.854 8.00066 23.6339 7.8206 23.3205L6.62688 21.1731H4.16608C3.43251 21.1731 2.83232 20.5729 2.83232 19.8393V17.3852L0.684953 16.1848C0.371518 16.0048 0.151447 15.7247 0.0514142 15.3779C-0.0419494 15.0378 -0.00193623 14.6777 0.171453 14.3642L1.49188 12.0035L0.171453 9.64269C-0.188663 8.99582 0.0447456 8.18222 0.684953 7.82211L2.83232 6.62172V4.16759C2.83232 3.43402 3.43251 2.83382 4.16608 2.83382H6.62688L7.8206 0.686461C8.00066 0.373026 8.28075 0.152954 8.62753 0.0529221C8.96764 -0.0404415 9.32775 -0.000428332 9.64119 0.172961L12.002 1.49339L14.3627 0.172961C14.6762 -0.000428332 15.0363 -0.0404415 15.3764 0.0529221C15.7232 0.152954 16.0032 0.373026 16.1833 0.686461L17.377 2.83382H19.8378C20.5714 2.83382 21.1716 3.43402 21.1716 4.16759V6.62172L23.319 7.82211C23.9592 8.18222 24.1926 8.99582 23.8325 9.64269L22.512 12.0035L23.8325 14.3642C24.0058 14.6777 24.0459 15.0378 23.9525 15.3779Z"
                    fill="#1975C9"
                />
                <path
                    d="M10.6677 16.0049C10.5801 16.005 10.4934 15.9878 10.4125 15.9543C10.3315 15.9207 10.2581 15.8716 10.1962 15.8095L6.86179 12.4751C6.7981 12.4136 6.7473 12.34 6.71235 12.2587C6.6774 12.1773 6.659 12.0898 6.65823 12.0012C6.65746 11.9127 6.67433 11.8249 6.70786 11.7429C6.74139 11.661 6.79091 11.5865 6.85353 11.5239C6.91614 11.4613 6.9906 11.4117 7.07256 11.3782C7.15452 11.3447 7.24233 11.3278 7.33088 11.3286C7.41943 11.3293 7.50694 11.3477 7.5883 11.3827C7.66966 11.4176 7.74325 11.4685 7.80477 11.5321L10.6677 14.3951L16.865 8.19773C16.9266 8.13404 17.0001 8.08323 17.0815 8.04828C17.1629 8.01333 17.2504 7.99494 17.3389 7.99417C17.4275 7.9934 17.5153 8.01027 17.5972 8.0438C17.6792 8.07733 17.7537 8.12685 17.8163 8.18947C17.8789 8.25208 17.9284 8.32654 17.9619 8.4085C17.9955 8.49046 18.0123 8.57827 18.0116 8.66682C18.0108 8.75537 17.9924 8.84287 17.9575 8.92424C17.9225 9.0056 17.8717 9.07919 17.808 9.1407L11.1392 15.8095C11.0773 15.8716 11.0038 15.9207 10.9229 15.9543C10.842 15.9878 10.7553 16.005 10.6677 16.0049Z"
                    fill="white"
                />
            </svg>

        </>
    )
}

export default VerifiedIcon