import React from 'react'

const NdrDeliveredIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={32}
                height={32}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M448 330.07V112a8.011 8.011 0 0 0-4.42-7.16l-192-96a8.018 8.018 0 0 0-6.93-.1l-208 96A7.978 7.978 0 0 0 32 112v256a7.978 7.978 0 0 0 4.65 7.26l208 96a8 8 0 0 0 6.93-.1l51.92-25.97A95.99 95.99 0 1 0 448 330.07ZM331.38 66.63l-171.04 92.1-43.82-20.22L292.31 47.1ZM152 172.5v127.37l-40-17.15V154.04Zm95.86-147.62 26.77 13.38-174.32 90.64a8.133 8.133 0 0 0-1.81 1.29L59.09 112ZM240 451.5 48 362.88V124.5l48 22.16V288a8 8 0 0 0 4.85 7.35l56 24a7.866 7.866 0 0 0 3.15.65 8 8 0 0 0 8-8V179.89l72 33.23Zm7.86-252.38-69.35-32 170.37-91.74L422.11 112Zm50.64 230.69L256 451.06V212.94l176-88v195.8a96.021 96.021 0 0 0-133.5 109.07ZM392 488a80 80 0 1 1 80-80 80.093 80.093 0 0 1-80 80Z"
                        fill="#f31429"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M426.343 386.343 384 428.687l-18.343-18.344a8 8 0 0 0-11.314 11.314l24 24a8 8 0 0 0 11.314 0l48-48a8 8 0 0 0-11.314-11.314Z"
                        fill="#f31429"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default NdrDeliveredIcon