import React from 'react'

const AddTagIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 28 28"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <g data-name="Layer 2">
                        <path
                            d="M25.969 1.029h-10a1 1 0 0 0-.707.293L13.183 3.4A6.991 6.991 0 1 1 3.4 13.183l-1.381 1.382a3.488 3.488 0 0 0 0 4.928l6.486 6.486a3.484 3.484 0 0 0 4.928 0l13.243-13.243a1 1 0 0 0 .293-.707v-10a1 1 0 0 0-1-1zM22 8a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
                            fill="#1975c9"
                            opacity={1}
                            data-original="#000000"
                            className=""
                        />
                        <path
                            d="M9 14a5 5 0 1 0-5-5 5.006 5.006 0 0 0 5 5zM7 8h1V7a1 1 0 0 1 2 0v1h1a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0v-1H7a1 1 0 0 1 0-2z"
                            fill="#1975c9"
                            opacity={1}
                            data-original="#000000"
                            className=""
                        />
                    </g>
                </g>
            </svg>


        </>
    )
}

export default AddTagIcon