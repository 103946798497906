import React from 'react'

const PhoneIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 1707 1707"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                fillRule="evenodd"
                className=""
            >
                <g>
                    <path
                        fill="#3e495a"
                        d="M1196 1707H510c-90 0-163-74-163-164V163C347 73 420 0 510 0h686c91 0 164 73 164 163v1380c0 90-73 164-164 164z"
                        opacity={1}
                        data-original="#3e495a"
                        className=""
                    />
                    <path
                        fill="#ffffff"
                        d="M1320 256H387v1195h933V256z"
                        opacity={1}
                        data-original="#b7d7fe"
                        className=""
                    />
                    <path
                        fill="#738191"
                        d="M598 0h511l-59 144c-3 7-10 12-19 12H675c-8 0-15-5-18-12zM1012 1599H695c-26 0-26-40 0-40h317c26 0 26 40 0 40z"
                        opacity={1}
                        data-original="#738191"
                        className=""
                    />
                    <path
                        fill="#ffffff"
                        d="m387 1451 933-1195H387z"
                        opacity={1}
                        data-original="#a8cfff"
                        className=""
                    />
                </g>
            </svg>


        </>
    )
}

export default PhoneIcon