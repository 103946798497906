
import axios from "../../../../axios/index";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { API_URL, BASE_URL_ORDER } from "../../../../axios/config";
import { GET_REPORT_SCHEDULER_DATA,  } from "../../../constants/tools";
import { POST_REPORT_SCHEDULER_RESPONSE, REPORT_SCHEDULER_DELETE_ACTION, REPORT_SCHEDULER_GET_ACTION, REPORT_SCHEDULER_POST_ACTION } from "../../constant/tools";
import { toast } from "react-toastify";
import { customErrorFunction } from "../../../../customFunction/errorHandling";


//GET REPORT SCHEDULER LIST API
/*async function getReportSchedulerAPI() {
    try {
        const response = await axios.get(`${BASE_URL_ORDER}${API_URL.GET_REPORT_SCHEDULER}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
}
function* getReportSchedulerAction() {
    try {
        const response = yield call(getReportSchedulerAPI);
        yield put({ type: GET_REPORT_SCHEDULER_DATA, payload: response });
    } catch (error) {
        console.log(error)
    }
}*/
//GET REPORT SCHEDULER LIST API
async function getReportSchedulerAPI(data) {
    return axios.request({
        method: "GET",
        url: `${BASE_URL_ORDER}${API_URL.GET_REPORT_SCHEDULER}?page_size=${data?.itemsPerPage}&page=${data?.currentPage}`,
        data: data
    });
}
function* getReportSchedulerAction(action) {
    let { payload } = action;
    try {
        let response = yield call(getReportSchedulerAPI, payload);
        console.log(response,"this is getReportSchedulerAPI dta")
        if (response.status === 200) {
            yield put({ type: GET_REPORT_SCHEDULER_DATA, payload: response?.data })
        }
    } catch (error) {
        customErrorFunction(error)
    }
}

//CREATE REPORT SCHEDULER API
async function postReportSchedulerAPI(data) {
    try {
        const response = await axios.post(`${BASE_URL_ORDER}${API_URL.GET_REPORT_SCHEDULER}`, data);
        return response;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
}
function* postReportSchedulerAction(action) {
    const { payload } = action;
    try {
        const response= yield call(postReportSchedulerAPI, payload);
        if (response.status === 201) {
            toast.success("Reports created successfully!")
            yield put({ type: POST_REPORT_SCHEDULER_RESPONSE, payload: response?.status});
            yield call(getReportSchedulerAction);
        }
    } catch (error) {
        customErrorFunction(error)
    }
}

//DELETE REPORT SCHEDULER API
async function deleteReportSchedulerAPI(data) {
    try {
        const response = await axios.delete(`${BASE_URL_ORDER}${API_URL.GET_REPORT_SCHEDULER}${data}`, );
         return response
    } catch (error) {
        throw new Error(error.response.data.message);
    }
}

function* deleteReportSchedulerAction(action) {
    const { payload } = action;
    try {
       const response= yield call(deleteReportSchedulerAPI, payload);
       if (response.status === 204) {
        toast.success("Reports Deleted successfully!")
        yield call(getReportSchedulerAction);
    }
    } catch (error) {
        customErrorFunction(error)
    }
}

export function* reportSchedulerWatcher() {
    yield takeLatest(REPORT_SCHEDULER_GET_ACTION, getReportSchedulerAction); 
    yield takeLatest(REPORT_SCHEDULER_POST_ACTION, postReportSchedulerAction); 
    yield takeLatest(REPORT_SCHEDULER_DELETE_ACTION, deleteReportSchedulerAction); 
}
