import React from 'react'

const YetToPickIcon = () => {
    return (
        <>
            <svg
                width={32}
                height={32}
                viewBox="0 0 524 462"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.882 462C237.755 462 236.629 461.793 235.541 461.379L5.87356 372.121C4.14439 371.447 2.65878 370.267 1.6109 368.736C0.563011 367.205 0.00158792 365.394 0 363.539V98.4663C0.000162399 96.611 0.56102 94.799 1.60909 93.2677C2.65716 91.7364 4.14357 90.5572 5.87356 89.8847L235.541 0.626557C237.69 -0.208852 240.075 -0.208852 242.224 0.626557L471.903 89.8858C473.633 90.5586 475.119 91.7379 476.167 93.2691C477.215 94.8003 477.776 96.6122 477.776 98.4675V253.079H459.354V111.919L248.093 194.025V439.342L362.204 394.998L368.88 412.156L242.224 461.38C241.158 461.79 240.025 462 238.882 462ZM229.674 194.028L172.066 171.637V221.916C172.066 226.68 169.268 231.049 164.939 233.048C163.332 233.786 161.585 234.167 159.817 234.167C156.948 234.167 154.106 233.164 151.853 231.243L120.262 204.346L91.9393 213.147C88.2358 214.299 84.1443 213.62 81.0291 211.322C79.4805 210.182 78.2214 208.694 77.3532 206.979C76.485 205.264 76.0319 203.368 76.0305 201.446V134.311L18.4219 111.92V357.242L229.674 439.345V194.028ZM443.149 98.4652L392.521 78.7911C324.434 105.255 256.348 131.719 188.266 158.178L238.882 177.849L443.149 98.4652ZM85.2426 118.138L289.491 38.7515L238.882 19.0843L34.6266 98.4652L85.2426 118.138ZM153.644 164.478L94.4501 141.47V193.084L119.626 185.26C121.109 184.798 122.685 184.72 124.206 185.031C125.728 185.342 127.146 186.033 128.328 187.04L153.644 208.585V164.478ZM110.65 128.013L162.849 148.3C230.934 121.847 299.024 95.3814 367.109 68.9161L314.901 48.6265L110.65 128.013Z"
                    fill="#1975C9"
                />
                <path
                    d="M421.157 231.246C364.442 231.246 318.314 277.369 318.314 334.039C318.314 390.709 364.442 436.831 421.157 436.831C477.873 436.831 524 390.709 524 334.039C524 277.369 477.873 231.246 421.157 231.246ZM421.157 410.016C379.233 410.016 345.143 375.924 345.143 334.039C345.143 292.153 379.233 258.062 421.157 258.062C463.081 258.062 497.172 292.153 497.172 334.039C497.172 375.924 463.081 410.016 421.157 410.016ZM469.538 334.039C469.538 337.595 468.125 341.005 465.609 343.519C463.094 346.034 459.682 347.446 456.124 347.446H421.157C417.6 347.446 414.188 346.034 411.672 343.519C409.156 341.005 407.743 337.595 407.743 334.039V297.659C407.743 294.103 409.156 290.693 411.672 288.178C414.188 285.664 417.6 284.251 421.157 284.251C424.715 284.251 428.127 285.664 430.643 288.178C433.158 290.693 434.572 294.103 434.572 297.659V320.631H456.124C459.682 320.631 463.094 322.044 465.609 324.558C468.125 327.072 469.538 330.483 469.538 334.039Z"
                    fill="#1975C9"
                />
            </svg>

        </>
    )
}

export default YetToPickIcon