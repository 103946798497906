export const DASHBOARD_OVERVIEW_SHIPMENTCARD_ACTION="DASHBOARD_OVERVIEW_SHIPMENTCARD_ACTION"
export const DASHBOARD_OVERVIEW_NDR_DETAILS_ACTION="DASHBOARD_OVERVIEW_NDR_DETAILS_ACTION"
export const DASHBOARD_OVERVIEW_COD_DETAILS_ACTION="DASHBOARD_OVERVIEW_COD_DETAILS_ACTION"
export const DASHBOARD_OVERVIEW_RTO_DETAILS_ACTION="DASHBOARD_OVERVIEW_RTO_DETAILS_ACTION"
export const DASHBOARD_OVERVIEW_LAST_ORDERS_ACTION="DASHBOARD_OVERVIEW_LAST_ORDERS_ACTION"
export const DASHBOARD_OVERVIEW_REVENUE_CARD_ACTION="DASHBOARD_OVERVIEW_REVENUE_CARD_ACTION"
export const DASHBOARD_OVERVIEW_COUNTER_CARD_ACTION="DASHBOARD_OVERVIEW_COUNTER_CARD_ACTION"
export const DASHBOARD_OVERVIEW_TOPSELL_ACTION="DASHBOARD_OVERVIEW_TOPSELL_ACTION"
export const DASHBOARD_OVERVIEW_STATEWISE_SPLIT_ACTION="DASHBOARD_OVERVIEW_STATEWISE_SPLIT_ACTION"
export const DASHBOARD_OVERVIEW_DELIVERY_PERFORMANCE_ACTION="DASHBOARD_OVERVIEW_DELIVERY_PERFORMANCE_ACTION"
export const DASHBOARD_OVERVIEW_COURIERWISE_ALLOCATION_ACTION="DASHBOARD_OVERVIEW_COURIERWISE_ALLOCATION_ACTION"
export const DASHBOARD_OVERVIEW_MOSTPOPULAR_CUSTOMER_ACTION="DASHBOARD_OVERVIEW_MOSTPOPULAR_CUSTOMER_ACTION"
export const DASHBOARD_OVERVIEW_WEIGHT_DISCREPANCIES_ACTION="DASHBOARD_OVERVIEW_WEIGHT_DISCREPANCIES_ACTION"