import React from 'react'

const ErrorIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 450 450"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <g fillRule="evenodd" clipRule="evenodd">
                        <circle
                            cx="225.26"
                            cy="225.74"
                            r="184.32"
                            fill="#FC3B3B"
                            transform="rotate(-45 225.232 225.798)"
                            opacity={1}
                            data-original="#fe646f"
                            className="hovered-path"
                        />
                        <g fill="#e6e9ed">
                            <path
                                d="M225.26 281.35c-4.09 0-7.59-3.49-8.3-8.29l-20.92-141.1c-2.63-17.77 8.85-34.06 24-34.06h10.43c15.15 0 26.63 16.29 24 34.06l-20.92 141.1c-.7 4.8-4.2 8.29-8.29 8.29zM244.4 334.43c0-10.54-8.6-19.14-19.14-19.14s-19.14 8.6-19.14 19.14 8.6 19.14 19.14 19.14 19.14-8.59 19.14-19.14z"
                                fill="#e6e9ed"
                                opacity={1}
                                data-original="#e6e9ed"
                            />
                        </g>
                    </g>
                </g>
            </svg>

        </>
    )
}

export default ErrorIcon