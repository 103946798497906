import React from 'react'

const IvrIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 240 240"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M120 240C186.274 240 240 186.274 240 120C240 53.7258 186.274 0 120 0C53.7258 0 0 53.7258 0 120C0 186.274 53.7258 240 120 240Z"
                    fill="#1975C9"
                />
                <path
                    d="M119.999 0.000118274C94.048 -0.00418812 68.8035 8.45559 48.0952 24.0961C30.7236 47.1709 22.2707 75.7435 24.292 104.555C26.3134 133.367 38.673 160.48 59.0962 180.903C79.5194 201.326 106.632 213.686 135.444 215.707C164.256 217.729 192.828 209.276 215.903 191.904C229.307 174.099 237.481 152.908 239.504 130.713C241.528 108.518 237.322 86.1989 227.359 66.2629C217.396 46.3269 202.071 29.5644 183.106 17.8589C164.14 6.15338 142.286 -0.031189 119.999 0.000118274Z"
                    fill="#1975C9"
                />
                <path d="M112 144H128V192H112V144Z" fill="white" />
                <path
                    d="M52.1194 187.881C34.1173 169.878 24.0039 145.461 24.0039 120.001C24.0039 94.5414 34.1173 70.1244 52.1194 52.1211L63.4314 63.4331C48.4288 78.436 40.0005 98.784 40.0005 120.001C40.0005 141.218 48.4288 161.566 63.4314 176.569L52.1194 187.881ZM187.879 187.881L176.567 176.569C191.57 161.566 199.998 141.218 199.998 120.001C199.998 98.784 191.57 78.436 176.567 63.4331L187.879 52.1211C205.882 70.1244 215.995 94.5414 215.995 120.001C215.995 145.461 205.882 169.878 187.879 187.881Z"
                    fill="white"
                />
                <path
                    d="M172.48 156.578L159.36 147.426C164.984 139.387 168.001 129.813 168.001 120.002C168.001 110.191 164.984 100.617 159.36 92.5778L172.48 83.4258C179.979 94.1487 184 106.917 184 120.002C184 133.086 179.979 145.855 172.48 156.578ZM67.5201 156.578C60.0216 145.855 56 133.086 56 120.002C56 106.917 60.0216 94.1487 67.5201 83.4258L80.6401 92.5778C75.0159 100.617 71.9995 110.191 71.9995 120.002C71.9995 129.813 75.0159 139.387 80.6401 147.426L67.5201 156.578Z"
                    fill="white"
                />
                <path
                    d="M144 96C144 82.7452 133.255 72 120 72C106.745 72 96 82.7452 96 96V128C96 141.255 106.745 152 120 152C133.255 152 144 141.255 144 128V96Z"
                    fill="white"
                />
                <path
                    d="M120 72C119.336 72 118.72 72.144 118.064 72.192C114.177 76.5419 112.019 82.1662 112 88V120C112 126.365 114.529 132.47 119.029 136.971C123.53 141.471 129.635 144 136 144C136.664 144 137.28 143.856 137.936 143.808C141.823 139.458 143.981 133.834 144 128V96C144 89.6348 141.471 83.5303 136.971 79.0294C132.47 74.5286 126.365 72 120 72Z"
                    fill="white"
                />
                <path d="M112 160H128V192H112V160Z" fill="white" />
                <path
                    d="M148.424 207.2L146.736 206.352C138.412 202.277 129.267 200.158 120 200.158C110.732 200.158 101.587 202.277 93.2638 206.352L91.5758 207.2L84.4238 192.88L86.1118 192.08C96.6613 186.913 108.253 184.227 120 184.227C131.747 184.227 143.338 186.913 153.888 192.08L155.576 192.88L148.424 207.2Z"
                    fill="white"
                />
            </svg>
        </>
    )
}

export default IvrIcon
