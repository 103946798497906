export const GET_RATE_CARD_DATA="GET_RATE_CARD_DATA"
export const GET_RATE_CALCULATOR_DATA="GET_RATE_CALCULATOR_DATA"
export const GET_REPORT_SCHEDULER_DATA="GET_REPORT_SCHEDULER_DATA"
export const RATE_CALCULATOR_PREFILLED_DATA="RATE_CALCULATOR_PREFILLED_DATA"
export const GET_SERVICE_ABILITY_DATA="GET_SERVICE_ABILITY_DATA"
export const GET_ZONE_MAPPING_DATA="GET_ZONE_MAPPING_DATA"
export const GET_SHIPEASE_SERVICE_PINCODE="GET_SHIPEASE_SERVICE_PINCODE"
export const GET_COURIER_PARTNER_NAME_DATA="GET_COURIER_PARTNER_NAME_DATA"
export const GET_COURIER_SERVICEABLE_PINCODE_DATA="GET_COURIER_SERVICEABLE_PINCODE_DATA"
export const GET_COURIER_ALLOCATION_DATA="GET_COURIER_ALLOCATION_DATA"
export const GET_COURIER_ALLOCATION_POST_DATA="GET_COURIER_ALLOCATION_POST_DATA"
export const GET_COURIER_ALLOCATION_RULE_DATA="GET_COURIER_ALLOCATION_RULE_DATA"
export const GET_COURIER_ALLOCATION_RULE_POST_DATA="GET_COURIER_ALLOCATION_RULE_POST_DATA"
export const GET_COURIER_ALLOCATION_RULE_DELETE_DATA="GET_COURIER_ALLOCATION_RULE_DELETE_DATA"
export const GET_COURIER_ALLOCATION_RULE_EDIT_DATA="GET_COURIER_ALLOCATION_RULE_EDIT_DATA"
export const GET_COURIER_ALLOCATION_RULE_EDIT_POST_DATA="GET_COURIER_ALLOCATION_RULE_EDIT_POST_DATA"
export const GET_COURIER_ALLOCATION_RULE_STATUS_DATA="GET_COURIER_ALLOCATION_RULE_STATUS_DATA"
export const GET_COURIER_PARTNER_DATA="GET_COURIER_PARTNER_DATA"




