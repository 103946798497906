import React from 'react'

const TotalCustomersIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M256 207c47.972 0 87-39.028 87-87s-39.028-87-87-87-87 39.028-87 87 39.028 87 87 87zm0-144c31.43 0 57 25.57 57 57s-25.57 57-57 57-57-25.57-57-57 25.57-57 57-57zM432 207c30.327 0 55-24.673 55-55s-24.673-55-55-55-55 24.673-55 55 24.673 55 55 55zm0-80c13.785 0 25 11.215 25 25s-11.215 25-25 25-25-11.215-25-25 11.215-25 25-25zM444.1 241h-23.2c-27.339 0-50.939 16.152-61.693 39.352C337.066 256.182 305.263 241 269.979 241h-27.957c-35.284 0-67.087 15.182-89.228 39.352C142.039 257.152 118.439 241 91.1 241H67.9C30.46 241 0 271.276 0 308.49V417.7C0 433.856 13.194 447 29.412 447h91.727c1.538 17.9 16.59 32 34.883 32h199.957c18.292 0 33.344-14.1 34.883-32h90.679c16.796 0 30.46-13.61 30.46-30.34V308.49C512 271.276 481.54 241 444.1 241zM30 308.49C30 287.818 47.002 271 67.9 271h23.2c20.898 0 37.9 16.818 37.9 37.49v10.271c-10.087 26.264-8 42.004-8 98.239H30zm331 135.489a5.026 5.026 0 0 1-5.021 5.021H156.021a5.027 5.027 0 0 1-5.021-5.021v-81.957c0-50.19 40.832-91.022 91.021-91.022h27.957c50.19 0 91.022 40.832 91.022 91.021zm121-27.319c0 .517 5.592.34-91 .34 0-56.651 2.071-72.018-8-98.239V308.49c0-20.672 17.002-37.49 37.9-37.49h23.2c20.898 0 37.9 16.818 37.9 37.49zM80 207c30.327 0 55-24.673 55-55s-24.673-55-55-55-55 24.673-55 55 24.673 55 55 55zm0-80c13.785 0 25 11.215 25 25s-11.215 25-25 25-25-11.215-25-25 11.215-25 25-25z"
                        fill="#3bb54b"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>
        </>
    )
}

export default TotalCustomersIcon