export const BILLING_DATA_ACTION="BILLING_DATA_ACTION"
export const BILLING_SHIPING_DATA_ACTION="BILLING_SHIPING_DATA_ACTION"
export const BILLING_SHIPING_REMITANCE_DATA_ACTION="BILLING_SHIPING_REMITANCE_DATA_ACTION"
export const BILLING_SHIPING_RECHARGE_DATA_ACTION="BILLING_SHIPING_RECHARGE_DATA_ACTION"
export const BILLING_SHIPING_INVOICE_DATA_ACTION="BILLING_SHIPING_INVOICE_DATA_ACTION"
export const BILLING_SHIPING_RECEIPT_DATA_ACTION="BILLING_SHIPING_RECEIPT_DATA_ACTION"
export const BILLING_SHIPING_RECEIPT_EXPORT_DATA_ACTION="BILLING_SHIPING_RECEIPT_EXPORT_DATA_ACTION"
export const BILLING_SHIPING_REMITANCE_DOWNLOAD_DATA_ACTION="BILLING_SHIPING_REMITANCE_DOWNLOAD_DATA_ACTION"

export const BILLING_PASSBOOK_COUNTER_DATA_ACTION="BILLING_PASSBOOK_COUNTER_DATA_ACTION"
export const BILLING_RECHARGE_COUNTER_DATA_ACTION="BILLING_RECHARGE_COUNTER_DATA_ACTION"
export const BILLING_SHIPPING_COUNTER_DATA_ACTION="BILLING_SHIPPING_COUNTER_DATA_ACTION"

export const BILLING_REMITANCE_EXPORT_DATA_ACTION="BILLING_REMITANCE_EXPORT_DATA_ACTION"

export const BILLING_INVOICE_DOWNLOAD_DATA_ACTION="BILLING_INVOICE_DOWNLOAD_DATA_ACTION"
export const BILLING_SELLER_DATA_ACTION="BILLING_SELLER_DATA_ACTION"
