export const GET_DASHBOARD_ORDERS_STORE_BASED_DATA="GET_DASHBOARD_ORDERS_STORE_BASED_DATA"
export const GET_DASHBOARD_ORDERS_COUNT_DATA="GET_DASHBOARD_ORDERS_COUNT_DATA"
export const GET_DASHBOARD_ORDERS_CANCELLED_DATA="GET_DASHBOARD_ORDERS_CANCELLED_DATA"
export const GET_DASHBOARD_ORDERS_MPS_DATA="GET_DASHBOARD_ORDERS_MPS_DATA"
export const GET_DASHBOARD_ORDERS_ASSIGNED_PICKED_DATA="GET_DASHBOARD_ORDERS_ASSIGNED_PICKED_DATA"
export const GET_DASHBOARD_ORDERS_BUYERDEMOGRAPHIC_DATA="GET_DASHBOARD_ORDERS_BUYERDEMOGRAPHIC_DATA"
export const GET_DASHBOARD_ORDERS_PREPAID_COD_DATA="GET_DASHBOARD_ORDERS_PREPAID_COD_DATA"
export const GET_DASHBOARD_ORDERS_WAREHOUSE_INFO_DATA="GET_DASHBOARD_ORDERS_WAREHOUSE_INFO_DATA"
export const GET_DASHBOARD_ORDERS_SKU_PROJECT_DATA="GET_DASHBOARD_ORDERS_SKU_PROJECT_DATA"
export const GET_DASHBOARD_ORDERS_POPULAR_LOCATION_DATA="GET_DASHBOARD_ORDERS_POPULAR_LOCATION_DATA"
export const GET_DASHBOARD_ORDERS_INTVSDOM_DATA="GET_DASHBOARD_ORDERS_INTVSDOM_DATA"

