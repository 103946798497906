import React from 'react'

const AverageSellingPriceIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={22}
                height={22}
                x={0}
                y={0}
                viewBox="0 0 24 24"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M14.59 2a7.523 7.523 0 0 1 2.25 4h4.15v2h-4.03c-.26 3.9-3.51 7-7.47 7h-3.3l10.76 9h-3.13L3 15v-2h6.5a5.5 5.5 0 0 0 5.47-5H3V6h11.79c-.66-2.3-2.77-4-5.29-4H3V0h18v2z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default AverageSellingPriceIcon