import React from 'react'

const RegistrationIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={40}
                height={40}
                x={0}
                y={0}
                viewBox="0 0 682.667 682.667"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <defs>
                        <clipPath id="a" clipPathUnits="userSpaceOnUse">
                            <path
                                d="M0 512h512V0H0Z"
                                fill="#1975c9"
                                opacity={1}
                                data-original="#000000"
                            />
                        </clipPath>
                    </defs>
                    <path
                        d="M0 0v51.62"
                        style={{
                            strokeWidth: 15,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: 10,
                            strokeDasharray: "none",
                            strokeOpacity: 1
                        }}
                        transform="matrix(1.33333 0 0 -1.33333 282.36 599.92)"
                        fill="none"
                        stroke="#1975c9"
                        strokeWidth={15}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeDasharray="none"
                        strokeOpacity=""
                        data-original="#000000"
                        className="hovered-path"
                        opacity={1}
                    />
                    <path
                        d="M0 0v51.62"
                        style={{
                            strokeWidth: 15,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: 10,
                            strokeDasharray: "none",
                            strokeOpacity: 1
                        }}
                        transform="matrix(1.33333 0 0 -1.33333 400.307 599.92)"
                        fill="none"
                        stroke="#1975c9"
                        strokeWidth={15}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeDasharray="none"
                        strokeOpacity=""
                        data-original="#000000"
                        className="hovered-path"
                        opacity={1}
                    />
                    <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                        <path
                            d="M0 0h-359.269c-16.569 0-30 13.44-30 30v299.641c0 16.559 13.431 30 30 30h86.161"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(396.769 113.68)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0h86.16c16.57 0 30-13.44 30-30v-299.641c0-16.559-13.43-30-30-30H43.429"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(388.34 473.32)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0h-82.16c-2.21 0-4-1.79-4-4v-291.641c0-2.21 1.79-4 4-4h429c2.21 0 4 1.79 4 4V-4c0 2.21-1.79 4-4 4h-82.16"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(123.66 443.32)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0h-104.764c-20.089 0-36.374-16.285-36.374-36.374v-13.338a4.85 4.85 0 0 1 4.85-4.85H31.524a4.85 4.85 0 0 1 4.85 4.85v13.338C36.374-16.285 20.089 0 0 0Z"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(308.382 62.062)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0v66.169c0 16.568 13.432 30 30 30h204.672c16.568 0 30-13.432 30-30v-330.816H0V-35"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(123.664 408.331)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0c-48.799 0-88.5 39.701-88.5 88.5S-48.799 177 0 177s88.5-39.701 88.5-88.5S48.799 0 0 0Z"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(256 282.5)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0c-5.122 0-10.242 1.948-14.141 5.846l-16.616 16.617c-5.857 5.858-5.857 15.355.001 21.213 5.858 5.857 15.356 5.859 21.213 0l7.422-7.423a3 3 0 0 1 4.243 0L39.469 73.6c5.711 5.711 15.014 6.237 20.988.801 6.377-5.803 6.553-15.686.525-21.713l-46.84-46.84C10.243 1.949 5.121 0 0 0Z"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(240.887 331.853)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0h172.713"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(169.643 233.5)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                        <path
                            d="M0 0h172.713"
                            style={{
                                strokeWidth: 15,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 10,
                                strokeDasharray: "none",
                                strokeOpacity: 1
                            }}
                            transform="translate(169.643 188.5)"
                            fill="none"
                            stroke="#1975c9"
                            strokeWidth={15}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity=""
                            data-original="#000000"
                            className="hovered-path"
                            opacity={1}
                        />
                    </g>
                </g>
            </svg>

        </>
    )
}

export default RegistrationIcon