import React from 'react'

const EmailIcon = () => {
    return (
        <>
            <svg
                width={30}
                height={30}
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_839_2923)">
                    <path
                        d="M0 435V76C0 33.4741 34.4741 -1 77 -1H435C477.526 -1 512 33.4741 512 76V435C512 477.526 477.526 512 435 512H77C34.4741 512 0 477.526 0 435Z"
                        fill="#60A9EB"
                    />
                    <path
                        d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                        fill="#60A9EB"
                    />
                    <path
                        d="M415.813 147.467H95.5578L211.577 268.273L245.057 302.173C226.841 298.009 225.714 295.414 217.642 288.824C213.408 285.367 205.228 275.972 192.804 263.284L88.2168 156.485V355.307L96.4818 363.572L95.5568 364.535L242.68 511.658C247.092 511.884 429.532 511.658 434 511.658C487.5 511.658 511.5 466 511.5 435C511.5 430.754 511.89 247.535 511.687 243.34L415.813 147.467Z"
                        fill="#1975C9"
                    />
                    <path
                        d="M88.2168 156.485V355.307L185.175 255.494L88.2168 156.485ZM423.783 156.485V355.307L327.307 255.896L423.783 156.485ZM95.5578 147.467H415.813L289.948 278.525C285.515 283.14 280.195 286.813 274.307 289.321C268.419 291.83 262.086 293.123 255.686 293.123C249.286 293.123 242.952 291.83 237.065 289.321C231.177 286.813 225.857 283.14 221.424 278.525L95.5578 147.467Z"
                        fill="white"
                    />
                    <path
                        d="M297.21 285.497C286.411 296.741 271.277 303.191 255.687 303.191C240.098 303.191 224.963 296.743 214.165 285.498L192.816 263.268L95.5586 364.535H415.814L318.558 263.268L297.21 285.497Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_839_2923">
                        <rect width={512} height={512} fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default EmailIcon