import React from 'react'

const ToolsIcons = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height={24}
                viewBox="0 -960 960 960"
                width={24}
            >
                <path d="M686-132 444-376q-20 8-40.5 12t-43.5 4q-100 0-170-70t-70-170q0-36 10-68.5t28-61.5l146 146 72-72-146-146q29-18 61.5-28t68.5-10q100 0 170 70t70 170q0 23-4 43.5T584-516l244 242q12 12 12 29t-12 29l-84 84q-12 12-29 12t-29-12Zm29-85 27-27-256-256q18-20 26-46.5t8-53.5q0-60-38.5-104.5T386-758l74 74q12 12 12 28t-12 28L332-500q-12 12-28 12t-28-12l-74-74q9 57 53.5 95.5T360-440q26 0 52-8t47-25l256 256ZM472-488Z" fill="#0F3C5B" />
            </svg> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        d="M481.429 332.892c-26.337-26.357-62.882-37.523-109.815-24.945L204.256 140.419l2.212-8.364c9.639-36.166-.776-75.041-27.172-101.437C152.42 3.721 114.212-6.148 78.077 3.778a14.996 14.996 0 0 0-10.529 10.631 15.001 15.001 0 0 0 3.909 14.438l40.297 40.297c11.781 11.81 11.666 30.724.029 42.392-11.545 11.576-30.951 11.558-42.45.029L29.028 71.257a14.993 14.993 0 0 0-14.454-3.891A14.993 14.993 0 0 0 3.962 77.917c-9.781 35.738-.159 74.183 26.846 101.188 26.326 26.345 62.825 37.551 109.786 24.946l167.371 167.528c-12.49 46.919-1.716 83.11 24.975 109.801 26.91 26.93 65.136 36.726 101.192 26.833a14.999 14.999 0 0 0 10.532-10.631 14.998 14.998 0 0 0-3.909-14.44l-40.288-40.288c-11.781-11.81-11.666-30.726-.029-42.392 11.689-11.629 31.052-11.444 42.45-.015l40.308 40.297a14.996 14.996 0 0 0 14.453 3.889 14.995 14.995 0 0 0 10.611-10.549c9.781-35.732.161-74.187-26.831-101.192z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                    <path
                        d="M160.551 266.584 17.559 409.594c-23.401 23.401-23.401 61.455 0 84.855 23.401 23.401 61.455 23.401 84.855 0l142.989-143.006-84.852-84.859zM88.322 447.898c-5.86 5.86-15.35 5.86-21.21 0-5.859-5.859-5.859-15.351 0-21.21l90.98-90.997c5.859-5.859 15.352-5.859 21.21 0 5.859 5.859 5.859 15.351 0 21.21l-90.98 90.997zM507.596 30.253 481.737 4.394a15 15 0 0 0-18.322-2.258l-79.547 47.723c-8.37 5.021-9.791 16.568-2.891 23.469l6.332 6.33-100.98 100.567 42.435 42.435 100.98-100.567 8.919 8.921c6.901 6.899 18.449 5.479 23.469-2.891l47.723-79.547a15.004 15.004 0 0 0-2.259-18.323z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                </g>
            </svg>


        </>
    )
}

export default ToolsIcons