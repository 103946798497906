export const RATE_CALCULATOR_ACTION= "RATE_CALCULATOR_ACTION"
export const RATE_CALCULATOR_ACTION_ORDER_ID= "RATE_CALCULATOR_ACTION_ORDER_ID"
export const RATE_CARD_ACTION= "RATE_CARD_ACTION"
export const REPORT_SCHEDULER_GET_ACTION= "REPORT_SCHEDULER_GET_ACTION"
export const REPORT_SCHEDULER_POST_ACTION= "REPORT_SCHEDULER_POST_ACTION"
export const REPORT_SCHEDULER_DELETE_ACTION= "REPORT_SCHEDULER_DELETE_ACTION"
export const SERVICE_ABILITY_PAIR_ACTION= "SERVICE_ABILITY_PAIR_ACTION"
export const SERVICE_ABILITY_SINGLE_ACTION= "SERVICE_ABILITY_SINGLE_ACTION"
export const ZONE_MAPPING_ACTION= "ZONE_MAPPING_ACTION"
export const GET_COURIER_PATNER_NAME_ACTION= "GET_COURIER_PATNER_NAME_ACTION"
export const GET_SHIPEASE_SERVICE_ABILITY_ACTION= "GET_SHIPEASE_SERVICE_ABILITY_ACTION"
export const GET_COURIER_SERVICE_ABILITY_FILTER_ACTION= "GET_COURIER_SERVICE_ABILITY_FILTER_ACTION"
export const COURIER_ALLOCATION_ACTION= "COURIER_ALLOCATION_ACTION"
export const COURIER_ALLOCATION_PARTNER_ACTION= "COURIER_ALLOCATION_PARTNER_ACTION"
export const COURIER_ALLOCATION_PARTNER_POST_ACTION= "COURIER_ALLOCATION_PARTNER_POST_ACTION"
export const POST_REPORT_SCHEDULER_RESPONSE="POST_REPORT_SCHEDULER_RESPONSE"
export const COURIER_ALLOCATION_RULE_ACTION= "COURIER_ALLOCATION_RULE_ACTION"
export const COURIER_ALLOCATION_RULE_POST_ACTION= "COURIER_ALLOCATION_RULE_POST_ACTION"
export const COURIER_ALLOCATION_RULE_DELETE_ACTION= "COURIER_ALLOCATION_RULE_DELETE_ACTION"
export const COURIER_ALLOCATION_RULE_EDIT_ACTION= "COURIER_ALLOCATION_RULE_EDIT_ACTION"
export const COURIER_ALLOCATION_RULE_EDIT_POST_ACTION= "COURIER_ALLOCATION_RULE_EDIT_POST_ACTION"
export const COURIER_ALLOCATION_RULE_STATUS_ACTION= "COURIER_ALLOCATION_RULE_STATUS_ACTION"
export const COURIER_PARTNER_ACTION= "COURIER_PARTNER_ACTION"



