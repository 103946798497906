import React from 'react'

const MergeIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_1013_3202)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 0C5.37225 0 0 5.37225 0 12C0 18.6277 5.37225 24 12 24C18.6277 24 24 18.6277 24 12C24 5.37225 18.6277 0 12 0ZM14.7803 14.0303L12 16.0605L9.21975 13.2803L10.2802 12.2197L11.25 13.1895V7.5H12.75V13.1895L13.7197 12.2197L14.7803 13.2803V14.0303ZM7.5 6H16.5V7.5H7.5V6Z"
                        fill="#1975C9"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1013_3202">
                        <rect width={24} height={24} fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default MergeIcon
