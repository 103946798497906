import React from 'react'

const ExportIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.623 2.50781C2.152 2.53481 1.305 2.65881 0.733 3.23281C5.96046e-08 3.96481 0 5.14281 0 7.49981V12.4998C0 14.8568 1.19209e-07 16.0358 0.732 16.7678C1.305 17.3408 2.152 17.4658 3.623 17.4928C3.5 16.5748 3.5 15.4288 3.5 14.0988V5.90181C3.5 4.57181 3.5 3.42481 3.623 2.50781Z"
                    fill="#1975C9"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.879 0.879C5 1.757 5 3.172 5 6V14C5 16.828 5 18.243 5.879 19.121C6.758 19.999 8.172 20 11 20H12C14.828 20 16.243 20 17.121 19.121C17.999 18.242 18 16.828 18 14V6C18 3.172 18 1.757 17.121 0.879C16.242 0.000999928 14.828 0 12 0H11C8.172 0 6.757 -5.96046e-08 5.879 0.879ZM12.03 5.469C11.8894 5.32855 11.6988 5.24966 11.5 5.24966C11.3012 5.24966 11.1106 5.32855 10.97 5.469L8.47 7.969C8.39834 8.03815 8.34116 8.12088 8.30181 8.21237C8.26246 8.30385 8.24173 8.40226 8.24081 8.50184C8.2399 8.60142 8.25883 8.70019 8.2965 8.79238C8.33417 8.88457 8.38982 8.96834 8.4602 9.03879C8.53059 9.10924 8.6143 9.16497 8.70646 9.20272C8.79861 9.24048 8.89736 9.2595 8.99694 9.25868C9.09653 9.25787 9.19495 9.23722 9.28647 9.19796C9.378 9.1587 9.46078 9.1016 9.53 9.03L10.75 7.81V14C10.75 14.1989 10.829 14.3897 10.9697 14.5303C11.1103 14.671 11.3011 14.75 11.5 14.75C11.6989 14.75 11.8897 14.671 12.0303 14.5303C12.171 14.3897 12.25 14.1989 12.25 14V7.81L13.47 9.03C13.5387 9.10369 13.6215 9.16279 13.7135 9.20378C13.8055 9.24477 13.9048 9.26682 14.0055 9.26859C14.1062 9.27037 14.2062 9.25184 14.2996 9.21412C14.393 9.1764 14.4778 9.12026 14.549 9.04904C14.6203 8.97782 14.6764 8.89299 14.7141 8.7996C14.7518 8.70621 14.7704 8.60618 14.7686 8.50548C14.7668 8.40478 14.7448 8.30546 14.7038 8.21346C14.6628 8.12146 14.6037 8.03866 14.53 7.97L12.03 5.469Z"
                    fill="#1975C9"
                />
            </svg>

        </>
    )
}

export default ExportIcon