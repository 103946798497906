import React from 'react'

const ListIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="m52.804 235.988 133.686 54.2L446.669 45.084c28.102-26.473 71.149 10.284 49.408 41.575L235.411 461.968c-12.005 17.315-37.181 18.779-51.289 3.426L18.626 288.4c-22.801-24.388 3.427-64.878 34.178-52.412z"
                        style={{
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                            strokeWidth: "20.0001",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: "22.9256"
                        }}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="none"
                        stroke="#1975C9"
                        strokeWidth="20.0001"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="22.9256"
                        data-original="#020288"
                        className=""
                        opacity={1}
                    />
                    <path
                        d="m91.174 300.102 115.832 123.879 183.419-264.088-194.089 182.844z"
                        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
                        fill="#60a9eb"
                        data-original="#a4fbc4"
                        className=""
                        opacity={1}
                    />
                </g>
            </svg>


        </>
    )
}

export default ListIcon