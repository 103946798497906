import React from 'react'

const CreateOrderIcon = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 468.032 468.032"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M430.006 384.005H123.644c-3.767 0-5.381-4.949-2.431-7.223l42.647-32.777h243.676c17.325 0 31.694-14.241 32.03-31.539l2.731-140.536c.312-16.021-11.42-29.932-27.289-32.304-7.634-1.142-14.783 4.107-15.927 11.752s4.131 14.759 11.783 15.903c1.989.297 3.459 2.025 3.42 4.027l-2.731 140.614c-.042 2.162-1.844 4.084-4.016 4.084H170.225L126.017 124.87l22.438 3.271c7.65 1.14 14.783-4.169 15.928-11.814 1.144-7.645-4.131-14.79-11.784-15.934L118.99 95.36l-12.848-49.943c-1.589-6.191-7.173-10.412-13.57-10.412H38.025c-7.737 0-14.01 6.27-14.01 14s6.272 14 14.01 14h43.677l61.707 261.119-39.309 30.283c-23.715 18.273-10.377 57.598 19.543 57.598h36.778c-1.628 4-2.522 9.1-2.522 14.107 0 23.158 18.975 41.92 42.299 41.92s42.299-18.723 42.299-41.881c0-5.007-.894-10.146-2.522-14.146h76.515c-1.628 4-2.522 9.1-2.522 14.107 0 23.158 18.975 41.92 42.299 41.92s42.3-18.723 42.3-41.881c0-5.007-.894-10.146-2.522-14.146h33.963c7.738 0 14.01-6.27 14.01-14s-6.274-14-14.012-14zM200.198 439.96c-7.874 0-14.279-6.282-14.279-14.004s6.406-14.004 14.279-14.004c7.874 0 14.28 6.282 14.28 14.004s-6.406 14.004-14.28 14.004zm156.068 0c-7.874 0-14.279-6.282-14.279-14.004s6.406-14.004 14.279-14.004c7.875 0 14.28 6.282 14.28 14.004s-6.406 14.004-14.28 14.004z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M280.228 202.922c-56.106 0-101.752-45.515-101.752-101.461S224.122 0 280.228 0 381.98 45.515 381.98 101.461s-45.646 101.461-101.752 101.461zm0-174.928c-40.656 0-73.732 32.957-73.732 73.467s33.077 73.467 73.732 73.467 73.732-32.957 73.732-73.467-33.076-73.467-73.732-73.467z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="m255.411 137.097-22.503-26.705c-4.983-5.913-4.225-14.743 1.694-19.722 5.918-4.979 14.755-4.222 19.74 1.693l12.364 14.673 38.469-40.874c5.3-5.631 14.167-5.904 19.803-.609 5.638 5.295 5.91 14.154.61 19.785l-49.253 52.333c-4.399 4.631-15.657 5.548-20.924-.574z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 468 468"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        fill="#ffffff"
                        d="M412.931 151.283 98.549 104.311l52.9 228.487h255.92c10.114 0 18.368-8.08 18.565-18.174l2.813-144.652c.181-9.311-6.588-17.311-15.816-18.689z"
                        opacity={1}
                        data-original="#ffffff"
                        className=""
                    />
                    <path
                        fill="#c5dcf1"
                        d="m153.605 112.697-55.055-8.146 52.9 228.568h255.934c10.107 0 18.354-8.08 18.551-18.174l.648-33.31-53.979-5.212c-97.876-9.45-182.327-72.586-218.999-163.726z"
                        opacity={1}
                        data-original="#e6e7e8"
                        className="hovered-path"
                    />
                    <path
                        fill="#1975c9"
                        d="M112.168 380.833 156.216 347h251.153c17.612 0 32.22-14.412 32.562-32.003l2.814-144.702c.318-16.294-11.609-30.424-27.743-32.834l-22.297-3.344-4.139 27.686 22.298 3.328c2.26.338 3.931 2.301 3.887 4.569l-2.813 144.752c-.048 2.452-2.097 4.546-4.567 4.546H162.578l-45.749-197.701 34.73 5.138 4.138-27.718-45.896-6.87-13.305-51.572C94.907 34.085 89.327 30 82.936 30H25.25v28h46.826l63.705 269.32-40.701 31.294C70.95 377.204 84.555 417 114.941 417H430.25v-28H114.941c-4.25 0-6.181-5.541-2.773-8.167z"
                        opacity={1}
                        data-original="#3e4d6c"
                        className=""
                    />
                    <ellipse
                        cx="354.558"
                        cy="431.961"
                        fill="#60a9eb"
                        rx="36.077"
                        ry="36.039"
                        opacity={1}
                        data-original="#0795fe"
                        className=""
                    />
                    <ellipse
                        cx="193.798"
                        cy="431.961"
                        fill="#60a9eb"
                        rx="36.077"
                        ry="36.039"
                        opacity={1}
                        data-original="#0795fe"
                        className=""
                    />
                    <ellipse
                        cx="276.234"
                        cy="97.82"
                        fill="#60a9eb"
                        rx="97.923"
                        ry="97.82"
                        opacity={1}
                        data-original="#0795fe"
                        className=""
                    />
                    <path
                        fill="#ffffff"
                        d="m251.006 134.26-23.181-27.503 21.41-18.045 13.053 15.487 39.962-42.454 20.389 19.191-50.733 53.897c-6.489 6.5-15.155 5.834-20.9-.573z"
                        opacity={1}
                        data-original="#ffffff"
                        className=""
                    />
                </g>
            </svg>
        </>
    )
}

export default CreateOrderIcon