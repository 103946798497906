import React from 'react'

const DragIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={30}
            height={30}
            x={0}
            y={0}
            viewBox="0 0 24 24"
            style={{ enableBackground: "new 0 0 512 512" }}
            xmlSpace="preserve"
            className=""
        >
            <g>
                <clipPath id="a">
                    <path
                        d="M0 0h24v24H0z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                    />
                </clipPath>
                <g clipPath="url(#a)">
                    <path
                        fill="#1975c9"
                        d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                        opacity={1}
                        data-original="#323232"
                        className=""
                    />
                </g>
            </g>
        </svg >
    )
}

export default DragIcon