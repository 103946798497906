import React from 'react'

const ShipmentsIcon = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height={24}
                viewBox="0 -960 960 960"
                width={24}
            >
                <path d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z" fill="#0F3C5B" />
            </svg> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 24 24"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M5.15 17.5H2.13l-.12.61c-.04.22.02.45.16.62s.36.27.58.27h2.29a3.317 3.317 0 0 1 .11-1.5zM15.83 7.28a.725.725 0 0 0-.58-.28H4.75a.76.76 0 0 0-.74.61L3.94 8h2.31C7.21 8 8 8.79 8 9.75c0 .64-.35 1.19-.86 1.5.51.31.86.86.86 1.5s-.35 1.19-.86 1.5c.31.19.56.47.71.81A3.504 3.504 0 0 1 12 18.5c0 .17-.01.34-.04.5H13c.35 0 .66-.25.73-.59l2.25-10.5c.05-.22 0-.45-.15-.63zM19.25 10h-2.14a.76.76 0 0 0-.74.59l-1.6 7.5c-.05.22 0 .45.15.63.14.18.35.28.58.28h.54c-.03-.16-.04-.33-.04-.5 0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5c0 .11 0 .21-.02.32.13-.1.22-.24.25-.41l.75-3.5c.01-.05.02-.11.02-.16 0-2.62-2.13-4.75-4.75-4.75z"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M6.25 10.5h-3.5a.75.75 0 0 1 0-1.5h3.5a.75.75 0 0 1 0 1.5zM6.25 13.5h-4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5zM6.25 16.5H.75a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 0 1.5zM19.5 21c-1.378 0-2.5-1.121-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.121 2.5 2.5-1.122 2.5-2.5 2.5zM8.5 21C7.122 21 6 19.879 6 18.5S7.122 16 8.5 16s2.5 1.121 2.5 2.5S9.878 21 8.5 21z"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>


        </>


    )
}

export default ShipmentsIcon