import React from 'react'

const WarehouseIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 24 24"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <g data-name="Layer 2">
                        <path
                            d="m21.65 5.05-9-3.6a1.77 1.77 0 0 0-1.3 0l-9 3.6a1.75 1.75 0 0 0-1.1 1.63V21A1.76 1.76 0 0 0 3 22.75h1.75a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 1 .5-.5h12.5a.5.5 0 0 1 .5.5v11a.5.5 0 0 0 .5.5H21A1.76 1.76 0 0 0 22.75 21V6.68a1.75 1.75 0 0 0-1.1-1.63z"
                            fill="#1975c9"
                            opacity={1}
                            data-original="#000000"
                            className="hovered-path"
                        />
                        <rect
                            width="7.5"
                            height="6.5"
                            x="8.25"
                            y="16.25"
                            rx=".75"
                            fill="#1975c9"
                            opacity={1}
                            data-original="#000000"
                            className="hovered-path"
                        />
                    </g>
                </g>
            </svg>

        </>
    )
}

export default WarehouseIcon