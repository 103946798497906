import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import NoData from '../../../../common/noData';
import CustomTooltip from '../../../../common/CustomTooltip/CustomTooltip';
import { weightGreater } from '../../../../../customFunction/functionLogic';

const ShippingCharges = ({ billingCard, selectedRows, selectAll, setSelectAll, setSelectedRows, setAwbNo, setOrderTracking, setBulkActionShow, setSelectedOrderRows, billingShippingCounterCard, partnerList }) => {
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [copyText, setcopyText] = useState("Tracking Link")


    const handleClose = () => setShow(false);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedRows(billingCard?.map(row => row?.id));
            setSelectedOrderRows(billingCard?.map(row => row?.id));
            setBulkActionShow(true)
        } else {
            setSelectedRows([]);
            setBulkActionShow(false)
            setSelectedOrderRows([]);
        }
    };

    const handleSelectRow = (orderId) => {
        const isSelected = selectedRows?.includes(orderId);
        if (isSelected) {
            setSelectedRows(selectedRows?.filter(id => id !== orderId));
            setSelectedOrderRows(selectedRows?.filter(id => id !== orderId));
            setBulkActionShow(true)
        } else {
            setSelectedRows([...selectedRows, orderId]);
            setSelectedOrderRows([...selectedRows, orderId]);
        }
        if (setSelectedRows !== ([])) {
            setBulkActionShow(true)
        }
        if (selectedRows.length === billingCard?.length - 1 && isSelected) {
            setSelectAll(false);
        } else {
            setSelectAll(false);
        }
    };

    const handleShow = (row) => {
        setSelectedRow(row);
        setShow(true);
    };

    const handleClickAWB = (awb) => {
        setOrderTracking(true)
        setAwbNo(awb)
    };

    const handleCopy = (awb) => {
        const temp_url = `https://shipease.in/order-tracking/${awb}`
        navigator.clipboard.writeText(temp_url)
            .then(() => {
                setcopyText("Copied")
                setTimeout(() => {
                    setcopyText('Tracking Link');
                }, 2000);
            })
            .catch(err => {
            });
    };

    return (
        <section className='position-relative'>
            <div className="position-relative">
                <div className="mb-3 billing-count-container">
                    <div className='box-shadow shadow-sm count-card'>
                        <p>Total Freight Charges: <span>&#8377; {billingShippingCounterCard?.total_freight_charge || 0}</span></p>
                    </div>
                    <div className='box-shadow shadow-sm count-card'>
                        <p>Billed Freight Charges: <span>&#8377; {billingShippingCounterCard?.billed_freight_charge || 0}</span></p>
                    </div>
                    <div className='box-shadow shadow-sm count-card'>
                        <p>Unbilled Freight Charges: <span>&#8377; {billingShippingCounterCard?.unbilled_freight_charge || 0}</span></p>
                    </div>
                    <div className='box-shadow shadow-sm count-card'>
                        <p>Total On-Hold Amount: <span>&#8377; {billingShippingCounterCard?.total_on_hold || 0}</span></p>
                    </div>
                    <div className='box-shadow shadow-sm count-card'>
                        <p>Invoice Due Amount: <span>&#8377; {billingShippingCounterCard?.invoice_due_amount || 0}</span></p>
                    </div>
                </div>
                <div className='table-container'>
                    <table className=" w-100">
                        <thead className="sticky-header">
                            <tr className="table-row box-shadow">
                                <th style={{ width: '1%' }}>
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>AWB Number</th>
                                <th>Courier Details</th>
                                <th style={{ width: '12%' }}>AWB Assigned Date</th>
                                <th>Shipment Status</th>
                                <th>Applied Weight Charges</th>
                                <th>Excess Weight Charges</th>
                                <th>Entered Weight and dimensions</th>
                                <th>Charged Weight and Dimensions</th>
                                <th>View Transaction Details</th>
                            </tr>
                            <tr className="blank-row"><td></td></tr>
                        </thead>
                        <tbody>
                            {billingCard?.map((row, index) => (
                                <React.Fragment key={row?.id}>
                                    {index > 0 && <tr className="blank-row"><td></td></tr>}
                                    <tr className='table-row box-shadow'>
                                        <td className='checkbox-cell'>
                                            <input
                                                type="checkbox"
                                                checked={selectedRows?.includes(row?.id)}
                                                onChange={() => handleSelectRow(row?.id, row?.id)}
                                            />
                                        </td>
                                        <td>
                                            <div className="d-flex">
                                                <div className='cell-inside-box'>
                                                    <p className='details-on-hover anchor-awb' onClick={(e) => handleClickAWB(row?.awb_number)}>
                                                        {row?.awb_number}
                                                    </p>
                                                </div>
                                                {row?.awb_number &&
                                                    <CustomTooltip
                                                        triggerComponent={<button className='btn copy-button p-0 ps-1' onClick={() => handleCopy(row?.awb_number)}><FaRegCopy /></button>}
                                                        tooltipComponent={copyText}
                                                        addClassName='copytext-tooltip'
                                                    />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box shipping-details'>
                                                {row?.courier_partner && <img src={partnerList[row?.courier_partner]["image"]} alt='Partner' />}
                                                <div>
                                                    <p className='mt-1 cursor-pointer text-capitalize'>
                                                        {row?.courier_partner && partnerList[row?.courier_partner]["title"]}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                <p className=''>
                                                    <span className=''>{`${moment(row?.awb_assigned_date).format('DD MMM YYYY')} || ${moment(row?.awb_assigned_date).format('h:mm A')}`}</span>
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                <p className='text-capitalize'>
                                                    {row?.status}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                <p className=''>
                                                    {row?.charge_detail?.total_charges || "-"}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                <p className=''>
                                                    {row?.charge_detail?.excess_weight_charges || "-"}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                {row?.dimension_detail?.weight ?
                                                    <p>Wt:  {weightGreater(row?.dimension_detail?.weight, row?.dimension_detail?.vol_weight)} kg
                                                        LBH(cm): {row?.dimension_detail?.length} x {row?.dimension_detail?.breadth} x {row?.dimension_detail?.height}
                                                    </p> : ""
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                {row?.charge_detail?.c_weight ? <p>Wt:  {row?.charge_detail?.c_weight} kg
                                                    LBH(cm): {row?.charge_detail?.c_length} x {row?.charge_detail?.c_breadth} x {row?.charge_detail?.c_height}
                                                </p> : "-"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='cell-inside-box'>
                                                <button className='btn main-button' onClick={() => handleShow(row)}>View</button>
                                            </div>
                                        </td>

                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    {billingCard?.length === 0 && <NoData />}
                </div>

                <Preview show={show} handleClose={handleClose} selectedRow={selectedRow} />
            </div>
        </section>
    );
};

export default ShippingCharges;

function Preview({ show, handleClose, selectedRow }) {
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>AWB CODE</th>
                            <th>Amount</th>
                            <th>Description</th>
                        </tr>
                        <tr>
                            <td>{moment(selectedRow?.awb_assigned_date).format("DD MMM YYYY") || moment(selectedRow?.awb_assigned_date).format("h:mm A")}</td>
                            <td>{selectedRow?.awb_number}</td>
                            <td>{selectedRow?.charge_detail?.total_charges}</td>
                            <td> Order Shipping Charges Deducted</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}