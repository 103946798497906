import React from 'react'

const NextIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_824_2960)">
                    <path
                        d="M512 256C512 206.933 509.867 162.133 501.333 136.533C492.8 106.667 482.133 78.9333 456.533 53.3333C424.533 23.4667 396.8 14.9333 354.133 6.4C324.267 2.13333 288 0 266.667 0H247.467C224 0 189.867 2.13333 157.867 6.4C115.2 14.9333 85.3333 25.6 55.4667 53.3333C27.7333 78.9333 19.2 106.667 10.6667 136.533C2.13333 162.133 0 206.933 0 256C0 305.067 2.13333 349.867 10.6667 375.467C19.2 405.333 29.8667 433.067 55.4667 458.667C87.4667 488.533 115.2 497.067 157.867 505.6C192 512 234.667 512 256 512C277.333 512 320 512 356.267 505.6C396.8 497.067 426.667 488.533 458.667 458.667C484.267 435.2 494.933 407.467 503.467 375.467C509.867 349.867 512 305.067 512 256Z"
                        fill="#60a9eb"
                    />
                    <path
                        d="M192.4 143.4C183.867 151.933 183.867 164.733 192.4 173.267L275.6 256.467L192.4 339.667C183.867 348.2 183.867 361 192.4 369.533C200.933 378.067 213.733 378.067 222.267 369.533L320.4 271.4C328.933 262.867 328.933 250.067 320.4 241.533L222.267 143.4C213.733 134.867 200.933 134.867 192.4 143.4Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_824_2960">
                        <rect width={512} height={512} fill="white" />
                    </clipPath>
                </defs>
            </svg>


        </>
    )
}

export default NextIcon