import React from 'react'

const CancelIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.6667 0H5.33333C3.91885 0 2.56229 0.561903 1.5621 1.5621C0.561903 2.56229 0 3.91885 0 5.33333V18.6667C0 20.0812 0.561903 21.4377 1.5621 22.4379C2.56229 23.4381 3.91885 24 5.33333 24H18.6667C20.0812 24 21.4377 23.4381 22.4379 22.4379C23.4381 21.4377 24 20.0812 24 18.6667V5.33333C24 3.91885 23.4381 2.56229 22.4379 1.5621C21.4377 0.561903 20.0812 0 18.6667 0ZM16 14.6667C16.164 14.85 16.2546 15.0874 16.2546 15.3333C16.2546 15.5793 16.164 15.8167 16 16C15.8167 16.164 15.5793 16.2546 15.3333 16.2546C15.0874 16.2546 14.85 16.164 14.6667 16L12 13.3333L9.33333 16C9.14999 16.164 8.91264 16.2546 8.66667 16.2546C8.42069 16.2546 8.18334 16.164 8 16C7.83602 15.8167 7.74536 15.5793 7.74536 15.3333C7.74536 15.0874 7.83602 14.85 8 14.6667L10.6667 12L8 9.33333C7.83602 9.14999 7.74536 8.91264 7.74536 8.66667C7.74536 8.42069 7.83602 8.18334 8 8C8.18197 7.83322 8.41983 7.7407 8.66667 7.7407C8.9135 7.7407 9.15137 7.83322 9.33333 8L12 10.6667L14.6667 8C14.8486 7.83322 15.0865 7.7407 15.3333 7.7407C15.5802 7.7407 15.818 7.83322 16 8C16.164 8.18334 16.2546 8.42069 16.2546 8.66667C16.2546 8.91264 16.164 9.14999 16 9.33333L13.3333 12L16 14.6667Z"
                    fill="#1975C9"
                />
            </svg>


        </>
    )
}

export default CancelIcon