import React from 'react'

const MoreOnOrdersIcon = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height={24}
                viewBox="0 -960 960 960"
                width={24}
            >
                <path d="m480-240 160-160-56-56-64 64v-168h-80v168l-64-64-56 56 160 160ZM200-640v440h560v-440H200Zm0 520q-33 0-56.5-23.5T120-200v-499q0-14 4.5-27t13.5-24l50-61q11-14 27.5-21.5T250-840h460q18 0 34.5 7.5T772-811l50 61q9 11 13.5 24t4.5 27v499q0 33-23.5 56.5T760-120H200Zm16-600h528l-34-40H250l-34 40Zm264 300Z" fill="#0F3C5B" />
            </svg> */}
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        fillRule="evenodd"
                        d="M73.974 127.993h62.338v33.85c0 5.9 4.783 10.683 10.683 10.683s10.683-4.783 10.683-10.683v-33.85h111.888v33.85c0 5.9 4.783 10.683 10.683 10.683s10.683-4.783 10.683-10.683v-33.85h62.337a13.319 13.319 0 0 1 13.297 12.131l10.05 109.335a139.882 139.882 0 0 0-14.463-.751c-76.34 0-138.226 61.886-138.226 138.226 0 18.776 3.751 36.674 10.532 52.995H61.785c-7.621 0-14.182-2.895-19.318-8.526-5.137-5.63-7.419-12.428-6.722-20.018l24.932-271.261a13.319 13.319 0 0 1 13.297-12.131zm62.338-1.759V88.15c0-21.271 8.694-40.601 22.701-54.608s33.337-22.701 54.608-22.701 40.601 8.694 54.608 22.701S290.93 66.88 290.93 88.15v38.084h-21.365V88.15c0-15.373-6.297-29.358-16.442-39.502-10.145-10.145-24.128-16.442-39.502-16.442-15.373 0-29.357 6.297-39.502 16.442-10.145 10.144-16.442 24.128-16.442 39.502v38.084zm225.84 146.474c-63.085 0-114.226 51.141-114.226 114.226S299.067 501.16 362.152 501.16s114.226-51.141 114.226-114.226-51.141-114.226-114.226-114.226zm-55.834 120.988 34.174 31.385c4.789 4.406 12.201 4.172 16.704-.472l60.979-58.384c4.763-4.582 4.91-12.159.328-16.922s-12.159-4.91-16.922-.328l-53.144 50.883-25.9-23.786c-4.867-4.478-12.444-4.163-16.922.703-4.479 4.866-4.164 12.443.703 16.921z"
                        clipRule="evenodd"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                </g>
            </svg> */}
            <svg
                width={28}
                height={24}
                viewBox="0 0 631 542"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M316.035 136.667H139.84C136.735 136.666 133.71 135.69 131.191 133.875C128.672 132.06 126.787 129.5 125.803 126.555L105.579 65.926C104.163 61.6763 101.446 57.9799 97.8123 55.3608C94.1785 52.7418 89.8126 51.3329 85.3333 51.334H21.3333C15.6754 51.334 10.2492 53.5816 6.24839 57.5824C2.24761 61.5831 0 67.0094 0 72.6673C0 78.3253 2.24761 83.7515 6.24839 87.7523C10.2492 91.753 15.6754 94.0007 21.3333 94.0007H59.3067C62.4076 94.006 65.4285 94.9849 67.9433 96.7993C70.4581 98.6136 72.3397 101.172 73.3227 104.113L143.659 315.163C146.795 324.55 147.179 334.619 144.789 344.219L139.008 367.409C130.496 401.414 156.971 435.334 192 435.334H448C453.658 435.334 459.084 433.086 463.085 429.086C467.086 425.085 469.333 419.659 469.333 414.001C469.333 408.343 467.086 402.916 463.085 398.916C459.084 394.915 453.658 392.667 448 392.667H192C183.68 392.667 178.347 385.841 180.373 377.798L184.747 360.347C185.483 357.396 187.185 354.775 189.581 352.9C191.977 351.026 194.931 350.005 197.973 350.001H426.667C431.15 350.006 435.52 348.599 439.158 345.98C442.796 343.361 445.517 339.662 446.933 335.409L453.743 314.967C375.391 292.941 317.653 221.672 316.035 136.667ZM140.497 469.164C148.498 461.163 159.351 456.667 170.667 456.667C181.983 456.667 192.835 461.163 200.837 469.164C208.838 477.166 213.333 488.018 213.333 499.334C213.333 510.65 208.838 521.502 200.837 529.504C192.835 537.505 181.983 542.001 170.667 542.001C159.351 542.001 148.498 537.505 140.497 529.504C132.495 521.502 128 510.65 128 499.334C128 488.018 132.495 477.166 140.497 469.164ZM396.497 469.164C404.498 461.163 415.351 456.667 426.667 456.667C437.983 456.667 448.835 461.163 456.837 469.164C464.838 477.166 469.333 488.018 469.333 499.334C469.333 510.65 464.838 521.502 456.837 529.504C448.835 537.505 437.983 542.001 426.667 542.001C415.351 542.001 404.498 537.505 396.497 529.504C388.495 521.502 384 510.65 384 499.334C384 488.018 388.495 477.166 396.497 469.164Z"
                    fill="black"
                />
                <path
                    d="M630.333 138.667C630.333 215.125 568.125 277.333 491.667 277.333C415.208 277.333 353 215.125 353 138.667C353 62.208 415.208 0 491.667 0C568.125 0 630.333 62.208 630.333 138.667ZM566.333 138.667C566.333 133.009 564.086 127.583 560.085 123.582C556.084 119.581 550.658 117.333 545 117.333H513V85.3333C513 79.6754 510.752 74.2492 506.752 70.2484C502.751 66.2476 497.325 64 491.667 64C486.009 64 480.582 66.2476 476.582 70.2484C472.581 74.2492 470.333 79.6754 470.333 85.3333V117.333H438.333C432.675 117.333 427.249 119.581 423.248 123.582C419.248 127.583 417 133.009 417 138.667C417 144.325 419.248 149.751 423.248 153.752C427.249 157.752 432.675 160 438.333 160H470.333V192C470.333 197.658 472.581 203.084 476.582 207.085C480.582 211.086 486.009 213.333 491.667 213.333C497.325 213.333 502.751 211.086 506.752 207.085C510.752 203.084 513 197.658 513 192V160H545C550.658 160 556.084 157.752 560.085 153.752C564.086 149.751 566.333 144.325 566.333 138.667Z"
                    fill="black"
                />
            </svg>




        </>
    )
}

export default MoreOnOrdersIcon