export const LOGIN_DATA="LOGIN_DATA"
export const SIGN_UP_DATA="SIGN_UP_DATA"
export const PATHNAME_ACTION="PATHNAME_ACTION"
export const PATHCLEAR_ACTION="PATHCLEAR_ACTION"
export const ZONE_MAPPING_PATH_ACTION="ZONE_MAPPING_PATH_ACTION"


//screen width type
export const SCREEN_WIDTH_DATA="SCREEN_WIDTH_DATA"

export const CHECK_AUTH_DATA="CHECK_AUTH_DATA"

