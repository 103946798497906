import React from 'react'

const QuickIcon = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <g
                        style={{
                            fill: "none",
                            stroke: "#474747",
                            strokeWidth: 15,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: 10
                        }}
                    >
                        <path
                            d="M275.697 174.284H68.621M235.179 227.717H114.762M262.565 245.812 388.381 82.788l-34.434 144.089h86.668L283.328 431.228l35.309-149.93H153.036"
                            fill="#1975c9"
                            opacity={1}
                            data-original="#000000"
                            className=""
                        />
                    </g>
                </g>
            </svg> */}
            <svg
                width={25}
                height={26}
                viewBox="0 0 359 364"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M172.565 170.811L298.381 7.78711L263.947 151.876H350.615L193.328 356.227L228.637 206.297L172.565 170.811Z"
                    fill="#60A9EB"
                    stroke="#1975C9"
                    strokeWidth={15}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M130.941 84.4615H205.765C209.506 84.4615 212 81.9692 212 78.2308C212 74.4923 209.506 72 205.765 72H130.941C127.2 72 124.706 74.4923 124.706 78.2308C124.706 81.9692 127.2 84.4615 130.941 84.4615ZM68.5882 84.4615H106C109.741 84.4615 112.235 81.9692 112.235 78.2308C112.235 74.4923 109.741 72 106 72H68.5882C64.8471 72 62.3529 74.4923 62.3529 78.2308C62.3529 81.9692 64.8471 84.4615 68.5882 84.4615ZM68.5882 134.308H168.353C172.094 134.308 174.588 131.815 174.588 128.077C174.588 124.338 172.094 121.846 168.353 121.846H68.5882C64.8471 121.846 62.3529 124.338 62.3529 128.077C62.3529 131.815 64.8471 134.308 68.5882 134.308ZM49.8824 128.077C49.8824 124.338 47.3882 121.846 43.6471 121.846H6.23529C2.49412 121.846 0 124.338 0 128.077C0 131.815 2.49412 134.308 6.23529 134.308H43.6471C47.3882 134.308 49.8824 131.815 49.8824 128.077ZM162.118 177.923C162.118 174.185 159.624 171.692 155.882 171.692H43.6471C39.9059 171.692 37.4118 174.185 37.4118 177.923C37.4118 181.662 39.9059 184.154 43.6471 184.154H155.882C159.624 184.154 162.118 181.662 162.118 177.923ZM168.353 221.538H130.941C127.2 221.538 124.706 224.031 124.706 227.769C124.706 231.508 127.2 234 130.941 234H168.353C172.094 234 174.588 231.508 174.588 227.769C174.588 224.031 172.094 221.538 168.353 221.538ZM112.235 227.769C112.235 224.031 109.741 221.538 106 221.538H81.0588C77.3176 221.538 74.8235 224.031 74.8235 227.769C74.8235 231.508 77.3176 234 81.0588 234H106C109.741 234 112.235 231.508 112.235 227.769Z"
                    fill="#1975C9"
                />
            </svg>

        </>

    )
}

export default QuickIcon