export const DASHBOARD_ORDERS_STORE_BASED_ACTION="DASHBOARD_ORDERS_STORE_BASED_ACTION"
export const DASHBOARD_ORDERS_COUNT_ACTION="DASHBOARD_ORDERS_COUNT_ACTION"
export const DASHBOARD_ORDERS_CANCELLED_ACTION="DASHBOARD_ORDERS_CANCELLED_ACTION"
export const DASHBOARD_ORDERS_MPS_ACTION="DASHBOARD_ORDERS_MPS_ACTION"
export const DASHBOARD_ORDERS_ASSIGNED_PICKED_ACTION="DASHBOARD_ORDERS_ASSIGNED_PICKED_ACTION"
export const DASHBOARD_ORDERS_BUYER_DEMOGRAPHIC_ACTION="DASHBOARD_ORDERS_BUYER_DEMOGRAPHIC_ACTION"
export const DASHBOARD_ORDERS_PREPAID_COD_ACTION="DASHBOARD_ORDERS_PREPAID_COD_ACTION"
export const DASHBOARD_ORDERS_WAREHOUSE_INFO_ACTION="DASHBOARD_ORDERS_WAREHOUSE_INFO_ACTION"
export const DASHBOARD_ORDERS_SKU_PROJECT_ACTION="DASHBOARD_ORDERS_SKU_PROJECT_ACTION"
export const DASHBOARD_ORDERS_POPULAR_LOCATION_ACTION="DASHBOARD_ORDERS_POPULAR_LOCATION_ACTION"
export const DASHBOARD_ORDERS_INTVSDOM_ACTION="DASHBOARD_ORDERS_INTVSDOM_ACTION"
