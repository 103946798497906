import React from 'react'

const DashboardIcon = () => {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        viewBox="0 -960 960 960"
        width={24}
      >
        <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z" fill="#0F3C5B" />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        x={0}
        y={0}
        viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className=""
      >
        <g>
          <path
            fill="#60a9eb"
            d="M197.332 0h-160C16.746 0 0 16.746 0 37.332v96c0 20.59 16.746 37.336 37.332 37.336h160c20.59 0 37.336-16.746 37.336-37.336v-96C234.668 16.746 217.922 0 197.332 0zm0 0"
            opacity={1}
            data-original="#1975C9"
            className=""
          />
          <path
            fill="#1976d2"
            d="M197.332 213.332h-160C16.746 213.332 0 230.078 0 250.668v224C0 495.254 16.746 512 37.332 512h160c20.59 0 37.336-16.746 37.336-37.332v-224c0-20.59-16.746-37.336-37.336-37.336zm0 0"
            opacity={1}
            data-original="#1976d2"
            className=""
          />
          <path
            fill="#60a9eb"
            d="M474.668 341.332h-160c-20.59 0-37.336 16.746-37.336 37.336v96c0 20.586 16.746 37.332 37.336 37.332h160C495.254 512 512 495.254 512 474.668v-96c0-20.59-16.746-37.336-37.332-37.336zm0 0"
            opacity={1}
            data-original="#1975C9"
            className=""
          />
          <path
            fill="#1976d2"
            d="M474.668 0h-160c-20.59 0-37.336 16.746-37.336 37.332v224c0 20.59 16.746 37.336 37.336 37.336h160c20.586 0 37.332-16.746 37.332-37.336v-224C512 16.746 495.254 0 474.668 0zm0 0"
            opacity={1}
            data-original="#1976d2"
            className=""
          />
        </g>
      </svg>


    </>


  )
}

export default DashboardIcon