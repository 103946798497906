import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PopularOrdersLocation = () => {

  const { popularLocation } = useSelector(state => state?.dashboardOrderReducer)

  return (
    <div className="box-shadow shadow-sm p10">
      <div className="row">
        <div className="col">
          <h4 className="title">Popular Orders Location</h4>
          <ul className="list-ui mt20">
            {popularLocation?.map((location, index) => (
              <li key={index} className={`bg-${getColor(index)}-light text-${getColor(index)} d-flex justify-content-between`}>
                <p>{location.p_state}</p>
                <p>{location.total}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Helper function to get color based on index
const getColor = (index) => {
  const colors = ['blue', 'blue', 'blue', 'blue', 'blue'];
  return colors[index % colors.length];
};

export default PopularOrdersLocation;
