export const GET_ORDERS_DATA="GET_ORDERS_DATA"
export const GET_ORDERS_DETAILS_DATA="GET_ORDERS_DETAILS_DATA"
export const ORDERS_DETAILS_RES_DATA="ORDERS_DETAILS_RES_DATA"
export const ORDERS_CANCEL_RES_DATA="ORDERS_CANCEL_RES_DATA"
export const ORDERS_CLONE_RES_DATA="ORDERS_CLONE_RES_DATA"
export const ORDERS_DELETE_RES_DATA="ORDERS_DELETE_RES_DATA"
export const GET_ORDER_ID_DATA="GET_ORDER_ID_DATA"
export const BULK_SHIP_DATA="BULK_SHIP_DATA"
export const SHIP_NOW_DATA="SHIP_NOW_DATA"
export const MANIFEST_LIST_DATA="MANIFEST_LIST_DATA"
export const BULK_ORDERS_TAG_LIST_DATA="BULK_ORDERS_TAG_LIST_DATA"
export const SAVE_FAV_LIST_DATA="SAVE_FAV_LIST_DATA"
export const ORDERS_LABEL_LIST_DATA="ORDERS_LABEL_LIST_DATA"
export const ORDERS_INVOICE_LIST_DATA="ORDERS_INVOICE_LIST_DATA"
export const ORDERS_DOWNLOAD_MANIFEST_DATA="ORDERS_DOWNLOAD_MANIFEST_DATA"
export const ORDERS_PRODUCT_DIMENSION_DATA="ORDERS_PRODUCT_DIMENSION_DATA"
export const ORDERS_DETAILS_CLONE_DATA="ORDERS_DETAILS_CLONE_DATA"
export const ORDER_SOURCE_DATA="ORDER_SOURCE_DATA"
export const ORDER_DATA="ORDER_DATA"




