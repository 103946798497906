import React from 'react'

const LastIcon = () => {
    return (


        <svg
            width={24}
            height={24}
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_822_2951)">
                <path
                    d="M512 256C512 206.933 509.867 162.133 501.333 136.533C492.8 106.667 482.133 78.9333 456.533 53.3333C424.533 23.4667 396.8 14.9333 354.133 6.4C324.267 2.13333 288 0 266.667 0H247.467C224 0 189.867 2.13333 157.867 6.4C115.2 14.9333 85.3333 25.6 55.4667 53.3333C27.7333 78.9333 19.2 106.667 10.6667 136.533C2.13333 162.133 0 206.933 0 256C0 305.067 2.13333 349.867 10.6667 375.467C19.2 405.333 29.8667 433.067 55.4667 458.667C87.4667 488.533 115.2 497.067 157.867 505.6C192 512 234.667 512 256 512C277.333 512 320 512 356.267 505.6C396.8 497.067 426.667 488.533 458.667 458.667C484.267 435.2 494.933 407.467 503.467 375.467C509.867 349.867 512 305.067 512 256Z"
                    fill="#60a9eb"
                />
                <path
                    d="M337.214 375L336.995 374.998C336.959 374.998 336.924 375 336.888 375H337.214ZM336.61 137L328.127 137.015C317.017 137.015 307.4 142.855 307.4 150.028V361.848C307.4 369.03 316.859 374.925 327.983 374.925L336.995 374.998C348.063 374.96 356.4 369.014 356.4 361.86V150.015C356.4 142.841 347.728 137 336.61 137Z"
                    fill="white"
                />
                <path
                    d="M161.4 143.404C152.867 151.937 152.867 164.737 161.4 173.271L244.6 256.471L161.4 339.671C152.867 348.204 152.867 361.004 161.4 369.537C169.933 378.071 182.733 378.071 191.267 369.537L289.4 271.404C297.933 262.871 297.933 250.071 289.4 241.537L191.267 143.404C182.733 134.871 169.933 134.871 161.4 143.404Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_822_2951">
                    <rect width={512} height={512} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LastIcon