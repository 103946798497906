import React from 'react'

const CustomerIcon = () => {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        viewBox="0 -960 960 960"
        width={24}
      >
        <path d="M475-160q4 0 8-2t6-4l328-328q12-12 17.5-27t5.5-30q0-16-5.5-30.5T817-607L647-777q-11-12-25.5-17.5T591-800q-15 0-30 5.5T534-777l-11 11 74 75q15 14 22 32t7 38q0 42-28.5 70.5T527-522q-20 0-38.5-7T456-550l-75-74-175 175q-3 3-4.5 6.5T200-435q0 8 6 14.5t14 6.5q4 0 8-2t6-4l136-136 56 56-135 136q-3 3-4.5 6.5T285-350q0 8 6 14t14 6q4 0 8-2t6-4l136-135 56 56-135 136q-3 2-4.5 6t-1.5 8q0 8 6 14t14 6q4 0 7.5-1.5t6.5-4.5l136-135 56 56-136 136q-3 3-4.5 6.5T454-180q0 8 6.5 14t14.5 6Zm-1 80q-37 0-65.5-24.5T375-166q-34-5-57-28t-28-57q-34-5-56.5-28.5T206-336q-38-5-62-33t-24-66q0-20 7.5-38.5T149-506l232-231 131 131q2 3 6 4.5t8 1.5q9 0 15-5.5t6-14.5q0-4-1.5-8t-4.5-6L398-777q-11-12-25.5-17.5T342-800q-15 0-30 5.5T285-777L144-635q-9 9-15 21t-8 24q-2 12 0 24.5t8 23.5l-58 58q-17-23-25-50.5T40-590q2-28 14-54.5T87-692l141-141q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l11 11 11-11q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l169 169q23 23 35 53t12 61q0 31-12 60.5T873-437L545-110q-14 14-32.5 22T474-80Zm-99-560Z" fill="#0F3C5B" />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        x={0}
        y={0}
        viewBox="0 0 64 64"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className="hovered-paths"
      >
        <g>
          <path
            d="M21.786 11.8c1.505-1.556 1.505-4.087 0-5.643A3.724 3.724 0 0 0 19.091 5h-.001a3.726 3.726 0 0 0-2.696 1.157L2.128 20.911c-1.504 1.556-1.504 4.087 0 5.643 1.442 1.492 3.95 1.492 5.393 0l1.517-1.569v-.001l3.592-3.715zM61.872 21.489 47.606 6.735a3.726 3.726 0 0 0-2.696-1.157h-.001a3.724 3.724 0 0 0-2.695 1.157c-1.505 1.556-1.505 4.087 0 5.643l1.129 1.167L55.41 26.027v.001l1.068 1.105c1.442 1.492 3.95 1.492 5.393 0 1.505-1.557 1.506-4.088.001-5.644zM15.048 41.598v-.165l1.83-1.892a1.941 1.941 0 0 0 0-2.676l-.512-.529c-.652-.674-1.888-.674-2.54 0l-3.201 3.31a1.941 1.941 0 0 0 0 2.676l.512.53c.326.337.789.531 1.271.531h.002c.481 0 .944-.194 1.268-.531l1.21-1.251zM42.35 14.909h-.26c-3.197 0-5.609 1.397-7.07 2.57-.405.324-.801.683-1.177 1.063-.364.365-.704.74-1.011 1.117l-.002.001a1.044 1.044 0 0 1-.159.151l-6.218 6.745c-.019.02-.039.039-.06.058a2.496 2.496 0 0 0-.877 1.933c.003.8.361 1.558.981 2.08a2.624 2.624 0 0 0 3.604-.191l5.779-5.911a.696.696 0 0 1 .905-.068l15.397 11.719.221-.484c.048-.104.097-.208.14-.311.101-.241.198-.485.29-.729.141-.378.266-.761.382-1.146l.042-.139c.059-.191.117-.382.168-.575.11-.423.198-.852.281-1.285l.047-.238c.026-.125.052-.25.072-.377a18.684 18.684 0 0 0 .228-3.777l-.004-.107zM21.468 42.397c0-.506-.189-.981-.535-1.337l-.512-.529c-.652-.674-1.888-.674-2.54 0l-3.201 3.31a1.941 1.941 0 0 0 0 2.676l.512.529c.325.337.788.531 1.27.531s.946-.194 1.271-.531l3.2-3.311c.346-.357.535-.832.535-1.338zM25.113 45.093l-.512-.531c-.651-.675-1.892-.673-2.541 0l-3.2 3.311a1.941 1.941 0 0 0 0 2.676l.512.529c.338.35.789.545 1.27.546.478 0 .929-.194 1.27-.546l3.201-3.311a1.937 1.937 0 0 0 0-2.674zM29.687 50.607c0-.506-.189-.981-.535-1.337l-.512-.531c-.677-.701-1.861-.705-2.54 0l-3.201 3.311a1.941 1.941 0 0 0 0 2.676l.512.529c.325.337.788.531 1.27.531s.946-.193 1.271-.531l3.2-3.311c.345-.356.535-.831.535-1.337z"
            fill="#000000"
            opacity={1}
            data-original="#000000"
            className="hovered-path"
          />
          <path
            d="M51.944 37.758 36.437 25.935l-5.353 5.477c-1.487 1.535-3.839 1.658-5.47.284a4.13 4.13 0 0 1-1.476-3.139 3.955 3.955 0 0 1 1.339-2.985l5.896-6.396-.159-.188c-2.328-2.747-5.602-4.261-9.219-4.261l-.214.002c-.15.002-.294.009-.436.02l-.104.008-8.406 8.693-2.454 2.537-.011.094a17.62 17.62 0 0 0 1.575 9.674l.171.358.723-.747c.572-.591 1.393-.931 2.254-.932h.001c.861 0 1.685.339 2.257.932l.512.53c.151.155.289.332.41.523.017.028.031.059.045.089l.064.126c.066.121.132.24.181.365.016.043.027.088.038.133l.047.17c.032.105.066.21.084.321.01.054.013.11.016.165l.014.165c.011.102.022.203.023.349l-.017.3.298-.005a3.201 3.201 0 0 1 2.341.964l.512.529c.147.152.282.323.4.506.02.034.038.07.054.105l.1.192c.048.087.097.174.132.265.018.047.031.096.044.143l.063.215c.025.079.051.158.067.241.011.06.015.119.019.178l.024.231c.009.063.017.126.018.19l.003.288.285-.013a3.116 3.116 0 0 1 2.456.959l.512.531c.149.153.286.328.409.521.02.034.037.07.053.104.017.037.033.073.052.107l.039.072c.053.098.107.195.145.296.018.048.03.097.042.146l.053.188c.029.095.058.188.075.284.01.055.013.111.016.166l.016.189c.01.095.019.188.021.332v.278l.349-.001c.853 0 1.653.342 2.256.965l.512.531a3.3 3.3 0 0 1 .924 2.309c0 .205-.019.416-.058.624a3.29 3.29 0 0 1-.865 1.686l-1.364 1.411 2.955 2.805a.668.668 0 0 1 .108.132c.339.534.792.972 1.314 1.267A3.746 3.746 0 0 0 34.97 59c.364 0 .73-.051 1.086-.156.88-.252 1.581-.785 1.975-1.5.383-.699.476-1.619.253-2.523a.706.706 0 0 1 .203-.681.685.685 0 0 1 .69-.145c1.025.354 2.03.307 2.616-.125.823-.603 1.32-2.344.726-4.211a.706.706 0 0 1 .223-.755.682.682 0 0 1 .777-.061c1.536.892 3.141.72 3.87.107.828-.697 1.267-2.502.572-4.315-.091-.237-.046-.506.115-.7s.408-.283.662-.235a3.156 3.156 0 0 0 1.915-.192c1.565-.689 2.16-2.591 1.94-4.113a4.03 4.03 0 0 0-.649-1.637z"
            fill="#000000"
            opacity={1}
            data-original="#000000"
            className="hovered-path"
          />
        </g>
      </svg>




    </>

  )
}

export default CustomerIcon