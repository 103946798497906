import React from 'react'

const CancelledOrdersIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={32}
                height={32}
                x={0}
                y={0}
                viewBox="0 0 32 32"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        fill="#f6b954"
                        d="M24.902 18.01c-3.307 0-5.997 2.69-5.997 5.997s2.69 5.997 5.997 5.997 5.998-2.69 5.998-5.997-2.691-5.997-5.998-5.997zm0 10.194c-2.314 0-4.196-1.883-4.196-4.197s1.882-4.197 4.196-4.197 4.197 1.883 4.197 4.197-1.882 4.197-4.197 4.197z"
                        opacity={1}
                        data-original="#f98a17"
                        className=""
                    />
                    <path
                        fill="#f6b954"
                        d="M26.773 22.137a.9.9 0 0 0-1.273 0l-.597.597-.597-.597a.9.9 0 1 0-1.273 1.272l.598.597-.598.597a.898.898 0 0 0 .637 1.536.897.897 0 0 0 .637-.264l.597-.597.597.597a.897.897 0 0 0 1.274 0 .898.898 0 0 0 0-1.272l-.598-.597.598-.597a.9.9 0 0 0-.002-1.272z"
                        opacity={1}
                        data-original="#f98a17"
                        className=""
                    />
                    <g fill="#6621ba">
                        <path
                            d="M23 3.796c1.158 0 2.1.942 2.1 2.1v9.58a.9.9 0 0 0 1.8 0v-9.58c0-2.15-1.75-3.9-3.9-3.9H5c-2.15 0-3.9 1.75-3.9 3.9v18c0 2.15 1.75 3.9 3.9 3.9h11.81a.9.9 0 0 0 0-1.8H5c-1.158 0-2.1-.942-2.1-2.1v-18c0-1.158.942-2.1 2.1-2.1h4.185v5.546c0 .794.646 1.44 1.44 1.44h6.75c.794 0 1.44-.646 1.44-1.44V3.796zm-5.985 5.186h-6.03V3.796h6.03z"
                            fill="#f6b954"
                            opacity={1}
                            data-original="#6621ba"
                            className=""
                        />
                        <path
                            d="M6.286 21.71a.9.9 0 0 0 0 1.8h3.719a.9.9 0 0 0 0-1.8z"
                            fill="#f6b954"
                            opacity={1}
                            data-original="#6621ba"
                            className=""
                        />
                    </g>
                </g>
            </svg>

        </>
    )
}

export default CancelledOrdersIcon