import React from 'react'

const PreviousIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={24}
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_824_2960)">
                    <path
                        d="M-1.52588e-05 256C-1.52588e-05 206.933 2.13332 162.133 10.6667 136.533C19.2 106.667 29.8667 78.9333 55.4667 53.3333C87.4667 23.4667 115.2 14.9333 157.867 6.4C187.733 2.13333 224 0 245.333 0H264.533C288 0 322.133 2.13333 354.133 6.4C396.8 14.9333 426.667 25.6 456.533 53.3333C484.267 78.9333 492.8 106.667 501.333 136.533C509.867 162.133 512 206.933 512 256C512 305.067 509.867 349.867 501.333 375.467C492.8 405.333 482.133 433.067 456.533 458.667C424.533 488.533 396.8 497.067 354.133 505.6C320 512 277.333 512 256 512C234.667 512 192 512 155.733 505.6C115.2 497.067 85.3333 488.533 53.3333 458.667C27.7333 435.2 17.0666 407.467 8.53331 375.467C2.13331 349.867 -1.52588e-05 305.067 -1.52588e-05 256Z"
                        fill="#60a9eb"
                    />
                    <path
                        d="M319.6 143.4C328.133 151.933 328.133 164.733 319.6 173.267L236.4 256.467L319.6 339.667C328.133 348.2 328.133 361 319.6 369.533C311.067 378.067 298.267 378.067 289.733 369.533L191.6 271.4C183.067 262.867 183.067 250.067 191.6 241.533L289.733 143.4C298.267 134.867 311.067 134.867 319.6 143.4Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_824_2960">
                        <rect
                            width={512}
                            height={512}
                            fill="white"
                            transform="matrix(-1 0 0 1 512 0)"
                        />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default PreviousIcon