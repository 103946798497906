import React from 'react'

const FirstIcon = () => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_822_2951)">
                <path
                    d="M-1.52588e-05 256C-1.52588e-05 206.933 2.13332 162.133 10.6667 136.533C19.2 106.667 29.8667 78.9333 55.4667 53.3333C87.4667 23.4667 115.2 14.9333 157.867 6.4C187.733 2.13333 224 0 245.333 0H264.533C288 0 322.133 2.13333 354.133 6.4C396.8 14.9333 426.667 25.6 456.533 53.3333C484.267 78.9333 492.8 106.667 501.333 136.533C509.867 162.133 512 206.933 512 256C512 305.067 509.867 349.867 501.333 375.467C492.8 405.333 482.133 433.067 456.533 458.667C424.533 488.533 396.8 497.067 354.133 505.6C320 512 277.333 512 256 512C234.667 512 192 512 155.733 505.6C115.2 497.067 85.3333 488.533 53.3333 458.667C27.7333 435.2 17.0666 407.467 8.53331 375.467C2.13331 349.867 -1.52588e-05 305.067 -1.52588e-05 256Z"
                    fill="#60a9eb"
                />
                <path
                    d="M174.786 375L175.005 374.998C175.041 374.998 175.076 375 175.112 375H174.786ZM175.39 137L183.873 137.015C194.983 137.015 204.6 142.855 204.6 150.028V361.848C204.6 369.03 195.141 374.925 184.017 374.925L175.005 374.998C163.937 374.96 155.6 369.014 155.6 361.86V150.015C155.6 142.841 164.272 137 175.39 137Z"
                    fill="white"
                />
                <path
                    d="M350.6 143.404C359.133 151.937 359.133 164.737 350.6 173.271L267.4 256.471L350.6 339.671C359.133 348.204 359.133 361.004 350.6 369.537C342.067 378.071 329.267 378.071 320.733 369.537L222.6 271.404C214.067 262.871 214.067 250.071 222.6 241.537L320.733 143.404C329.267 134.871 342.067 134.871 350.6 143.404Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_822_2951">
                    <rect
                        width={512}
                        height={512}
                        fill="white"
                        transform="matrix(-1 0 0 1 512 0)"
                    />
                </clipPath>
            </defs>
        </svg>

    )
}

export default FirstIcon