import React from 'react'

const FacebookIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={30}
                height={30}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z"
                        style={{}}
                        fill="#1976d2"
                        data-original="#1976d2"
                        className=""
                    />
                    <path
                        d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z"
                        style={{}}
                        fill="#fafafa"
                        data-original="#fafafa"
                    />
                </g>
            </svg>

        </>
    )
}

export default FacebookIcon