import React from 'react'

const OrderTagsIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 64 64"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g transform="matrix(-6.123233995736764e-17,-1,1,-6.123233995736764e-17,-1.7669515609741069,65.00695323944092)">
                    <path
                        d="m44.77 11.31-7.89-4.39-14.68 5.56L2.5 47.29l26.29 14.63L48.5 27.11zM36 13a4 4 0 1 1-4 4 4 4 0 0 1 4-4zm-9.11 43.5L8 45.79 23.11 19.5 42 30.21zM60.28 5.73l-4.62 1.06a7.86 7.86 0 0 1-4.6-.31l-3.31-1.25a6 6 0 0 0-6.43 1.48l-.06.07 1.94 1.06A3.87 3.87 0 0 1 47 7.2l3.31 1.25a10 10 0 0 0 5.81.4l4.62-1.07z"
                        fill="#60a9eb"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>


        </>
    )
}

export default OrderTagsIcon