import React from 'react'

const MISIcon = () => {
    return (
        <>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height={24}
                viewBox="0 -960 960 960"
                width={24}
            >
                <path d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-200v-80h320v80H320Zm10-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330Zm24-80h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79Zm126 0Z" fill="#0F3C5B" />
            </svg> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={24}
                height={24}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M414 28.055h-19.992c8.6 15.789 13.492 33.885 13.492 53.104 0 24.862-20.187 45.088-45 45.088h-213c-24.813 0-45-20.227-45-45.088 0-19.219 4.892-37.314 13.492-53.104H98c-24.813 0-45 20.227-45 45.088v393.769C53 491.773 73.187 512 98 512h316c24.813 0 45-20.227 45-45.088V73.143c0-24.862-20.187-45.088-45-45.088zM203.272 426.106l-32.666 32.73c-5.857 5.869-15.355 5.87-21.213 0l-16-16.031c-5.858-5.869-5.858-15.385 0-21.254 5.857-5.87 15.355-5.87 21.213 0l5.394 5.404 22.06-22.102c5.857-5.87 15.355-5.87 21.213 0 5.857 5.868 5.857 15.383-.001 21.253zm0-86.169-32.666 32.73c-5.857 5.869-15.355 5.87-21.213 0l-16-16.031c-5.858-5.869-5.858-15.385 0-21.254 5.857-5.87 15.355-5.87 21.213 0l5.394 5.404 22.06-22.102c5.857-5.87 15.355-5.87 21.213 0 5.857 5.868 5.857 15.384-.001 21.253zm-28.666-60.785c-5.857 5.87-15.355 5.87-21.213 0-5.858-5.869-5.858-15.385 0-21.254l54-54.106c5.857-5.87 15.355-5.87 21.213 0L274 249.273l35.787-35.857H308c-8.284 0-15-6.729-15-15.029s6.716-15.029 15-15.029c41.306 0 44.827-1.771 50.474 6.686 3.444 5.158 2.526 6.903 2.526 46.418 0 8.3-6.716 15.029-15 15.029s-15-6.729-15-15.029v-1.791l-46.394 46.484c-5.857 5.869-15.355 5.87-21.213 0L218 235.674l-43.394 43.478zM367 446.873H246c-8.284 0-15-6.729-15-15.029s6.716-15.029 15-15.029h121c8.284 0 15 6.729 15 15.029s-6.716 15.029-15 15.029zm0-86.169H246c-8.284 0-15-6.729-15-15.029s6.716-15.029 15-15.029h121c8.284 0 15 6.729 15 15.029s-6.716 15.029-15 15.029z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M296.5 0h-81c-44.664 0-81 36.408-81 81.159 0 8.3 6.716 15.029 15 15.029h213c8.284 0 15-6.729 15-15.029C377.5 36.408 341.164 0 296.5 0z"
                        fill="#000000"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>


        </>

    )
}

export default MISIcon