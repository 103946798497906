import React from 'react'

const TruckIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={40}
                height={40}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        d="M154.246 124.519h-19.813v-19.813c0-8.284-6.715-15-15-15-8.283 0-15 6.716-15 15v34.812c.001 8.285 6.717 15.001 15.001 15.001h34.812c8.285 0 15-6.716 15-15s-6.715-15-15-15z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                    <path
                        d="M119.434 20.084C53.578 20.084 0 73.662 0 139.518c0 65.854 53.578 119.431 119.434 119.431 65.855 0 119.434-53.577 119.434-119.432S185.29 20.084 119.434 20.084zm0 208.867C70.119 228.949 30 188.831 30 139.518c0-49.314 40.119-89.434 89.434-89.434 49.314 0 89.434 40.12 89.434 89.434 0 49.313-40.119 89.433-89.434 89.433z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                    <path
                        d="M510.461 277.529c-.02-.039-.025-.08-.045-.119l-48.199-96.4a14.999 14.999 0 0 0-13.416-8.292H335.268v-33.199c0-8.284-6.715-15-15-15h-52.15c.494 4.935.75 9.937.75 15 0 82.396-67.035 149.432-149.434 149.432-48.763 0-92.141-23.479-119.434-59.724v199.491c0 8.284 6.716 15 15 15h67.146c6.754 27.629 31.707 48.199 61.387 48.199 29.682 0 54.635-20.57 61.387-48.199h102.162c6.752 27.629 31.705 48.199 61.385 48.199s54.633-20.57 61.387-48.199H497c8.285 0 15-6.716 15-15v-144.6c0-2.368-.562-4.598-1.539-6.589zM143.533 461.916c-18.307 0-33.199-14.893-33.199-33.199 0-18.306 14.893-33.199 33.199-33.199s33.201 14.893 33.201 33.199c0 18.306-14.894 33.199-33.201 33.199zm224.934 0c-18.307 0-33.199-14.893-33.199-33.199 0-18.306 14.893-33.199 33.199-33.199 18.306 0 33.199 14.893 33.199 33.199 0 18.306-14.893 33.199-33.199 33.199zm-33.199-192.799v-66.4h104.264l33.199 66.4H335.268z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                </g>
            </svg>

        </>
    )
}

export default TruckIcon