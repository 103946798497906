import React from 'react'

const ActionRequiredIcon = () => {
    return (
        <>
            <svg
                width={24}
                height={21}
                viewBox="0 0 512 442"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M505.403 371.394L295.389 23.1024C287.115 9.38142 272.022 0.857422 255.999 0.857422C239.976 0.857422 224.883 9.38142 216.608 23.1034L6.59544 371.394C-1.95556 385.576 -2.20856 403.344 5.93444 417.764C14.0794 432.184 29.4254 441.142 45.9854 441.142H466.013C482.573 441.142 497.92 432.184 506.065 417.763C514.208 403.342 513.955 385.574 505.403 371.394ZM477.039 401.372C475.935 403.324 474.332 404.948 472.395 406.079C470.458 407.209 468.256 407.806 466.013 407.808H45.9854C43.7429 407.807 41.5409 407.21 39.6041 406.08C37.6673 404.949 36.0649 403.325 34.9604 401.373C33.8593 399.42 33.296 397.209 33.3278 394.966C33.3596 392.724 33.9854 390.53 35.1414 388.608L245.156 40.3164C246.288 38.4524 247.88 36.9104 249.779 35.8381C251.677 34.7657 253.82 34.1991 256 34.1924C260.41 34.1924 264.565 36.5394 266.843 40.3164L476.856 388.608C478.013 390.53 478.64 392.723 478.672 394.966C478.704 397.208 478.141 399.419 477.039 401.372Z"
                    fill="#F6B954"
                />
                <path
                    d="M256.155 138.006C243.475 138.006 233.579 144.81 233.579 156.872C233.579 193.674 237.908 246.558 237.908 283.361C237.909 292.948 246.26 296.968 256.156 296.968C263.578 296.968 274.093 292.948 274.093 283.361C274.093 246.559 278.422 193.675 278.422 156.872C278.422 144.811 268.217 138.006 256.155 138.006ZM256.466 318.307C242.859 318.307 232.652 329.131 232.652 342.121C232.652 354.801 242.858 365.935 256.466 365.935C269.146 365.935 279.971 354.801 279.971 342.121C279.971 329.131 269.145 318.307 256.466 318.307Z"
                    fill="#F6B954"
                />
            </svg>


        </>
    )
}

export default ActionRequiredIcon