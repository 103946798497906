import React from 'react'

const SendInvitaionIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={40}
                height={40}
                x={0}
                y={0}
                viewBox="0 0 511.999 511.999"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M366.271 109.228H145.725a7.5 7.5 0 0 0 0 14.998h220.546c4.142 0 7.499-3.357 7.499-7.499s-3.357-7.499-7.499-7.499zM366.271 148.149H145.725a7.5 7.5 0 0 0 0 14.998h220.546c4.142 0 7.499-3.357 7.499-7.499s-3.357-7.499-7.499-7.499zM249.208 187.068H145.725a7.498 7.498 0 0 0-7.499 7.499 7.498 7.498 0 0 0 7.499 7.499h103.482a7.5 7.5 0 0 0 7.499-7.499 7.497 7.497 0 0 0-7.498-7.499z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="m486.577 146.811-42.953-29.246V106.54c0-26.767-21.777-48.544-48.544-48.544h-38.946L283.464 8.52c-16.684-11.36-38.245-11.36-54.93 0l-72.606 49.434.029.043h-39.039c-26.767 0-48.544 21.777-48.544 48.544v11.024L25.421 146.81A42.466 42.466 0 0 0 6.84 181.941v298.196c0 10.384 4.187 31.861 31.861 31.861h434.596c17.569 0 31.861-14.293 31.861-31.861V181.941a42.464 42.464 0 0 0-18.581-35.13zM236.975 20.916c11.558-7.866 26.493-7.866 38.048 0l54.463 37.081H182.514l54.461-37.081zM83.373 106.542c0-18.498 15.048-33.546 33.545-33.546h278.161c18.497 0 33.545 15.048 33.545 33.545v56.75a7.5 7.5 0 0 0 14.998 0v-27.58s37.048 25.401 38.172 26.489L295.923 274.696c-24.625 14.869-55.222 14.871-79.809.023L83.373 193.456v-86.914zm-14.998 29.167v48.563L31.04 161.417c.887-.789 37.335-25.708 37.335-25.708zM37.807 496.956l26.595-30.538a7.5 7.5 0 1 0-11.311-9.851l-28.519 32.746a16.753 16.753 0 0 1-2.735-9.177V181.941c0-2.7.404-5.343 1.156-7.864l180.1 110.256L75.547 430.784a7.5 7.5 0 0 0 .73 10.581c1.421 1.237 6.321 3.264 10.581-.73l129.55-148.752a91.963 91.963 0 0 0 39.59 8.97c13.696 0 27.392-3.076 40.068-9.205l178.356 205.297c-.374.024-436.317.027-436.615.011zm452.353-16.819c0 3.318-.977 6.405-2.638 9.017L309.367 284.09l179.948-108.911c.554 2.186.846 4.453.846 6.762v298.196z"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default SendInvitaionIcon