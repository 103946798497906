import { toast } from "react-toastify";
import axios from "../../../../../axios/index"
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { ERROR_RESPONSE_DATA } from "../../../../constants/error";
import { API_URL, BASE_URL_ORDER } from "../../../../../axios/config";
import { customErrorFunction } from "../../../../../customFunction/errorHandling";
import { GET_ORDERS_DETAILS_DATA, ORDERS_DETAILS_RES_DATA, BULK_SHIP_DATA, BULK_ORDERS_TAG_LIST_DATA, SAVE_FAV_LIST_DATA, ORDERS_DETAILS_CLONE_DATA, ORDERS_CLONE_RES_DATA, ORDER_SOURCE_DATA, ORDER_DATA, ORDERS_CANCEL_RES_DATA } from "../../../../constants/orders";
import { ORDERS_DETAILS_GET_ACTION, ORDERS_DETAILS_UPDATE_ACTION, SAVE_FAVOURITE_ORDERS_ACTION, BULK_SHIP_ORDERS_ACTION, ORDERS_TAG_LIST_API_ACTION, GET_SAVE_FAVOURITE_ORDERS_ACTION, ORDERS_DETAILS_CLONE_ACTION, CREATE_ORDERS_TAG_ACTION, GET_ORDER_SOURCE_API_ACTION, GET_ORDER_DATA_ACTION } from "../../../constant/orders";


async function getOrderDataAPI(data) {
    let listData = axios.request({
        method: "GET",
        url: `${BASE_URL_ORDER}${API_URL.ORDER_DETAILS_API}${data}/`,
        data: data
    });
    return listData
}
function* fetchOrderDataAction(action) {
    let { payload } = action;
    try {
        let response = yield call(getOrderDataAPI, payload);
        if (response.status === 200) {
            yield put({ type: GET_ORDERS_DETAILS_DATA, payload: response?.data })
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

//UPDATE_ORDERS_API
async function updateOrderApi(data) {
    let listData = axios.request({
        method: "PUT",
        url: `${BASE_URL_ORDER}${API_URL.ORDER_DETAILS_API}${data.orderId}/`,
        data: data.formData
    });
    return listData
}
function* updateOrderAction(action) {
    let { payload, } = action;
    try {
        let response = yield call(updateOrderApi, payload);
        if (response.status === 200) {
            yield put({ type: ORDERS_DETAILS_RES_DATA, payload: response?.status })
            toast.success("Order update successfully")
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

//SAVE_FAVOURITE_ORDERS_API
async function saveFavouriteOrderAPI(data) {
    return axios.request({
        method: "POST",
        url: `${BASE_URL_ORDER}${API_URL.SAVE_FAVOURITE_ORDERS_API}`,
        data: data
    });
}
function* saveFavouriteOrdersAction(action) {
    let { payload,  } = action;
    try {
        let response = yield call(saveFavouriteOrderAPI, payload);
        if (response.status === 201) {
            yield put({ type: "GET_SAVE_FAVOURITE_ORDERS_ACTION" })
            toast.success("Filter added successfully!")
        }

    } catch (error) {
        customErrorFunction(error);
    }
}


async function saveFavListApi(data) {
    return axios.request({
        method: "GET",
        url: `${BASE_URL_ORDER}${API_URL.SAVE_FAVOURITE_ORDERS_API}`,
        data: data
    });
}
function* saveFavListAction(action) {
    let { payload,  } = action;
    try {
        let response = yield call(saveFavListApi, payload);
        if (response.status === 200) {
            yield put({ type: SAVE_FAV_LIST_DATA, payload: response?.data })
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

async function bulkShipOrderAPI(data) {
    return axios.request({
        method: "POST",
        url: `${BASE_URL_ORDER}${API_URL.BULK_SHIP_ORDERS_API}`,
        data: data
    });
}
function* bulkShipOrdersAction(action) {
    let { payload,  } = action;
    try {
        let response = yield call(bulkShipOrderAPI, payload);
        if (response.status === 200) {
            yield put({ type: BULK_SHIP_DATA, payload: response?.data })
            yield put({ type: ORDERS_CANCEL_RES_DATA, payload: response?.status })
            const shippedCount = Object.values(response?.data).reduce((total, order) => {
                if (order?.status === true) {
                    return total + 1;
                }
                return total;
            }, 0);
            if (shippedCount === 0) {
                toast.error("No orders shipped")
            } else {
                toast.success(`${shippedCount} out of ${payload?.order_ids?.length} Orders Shipped Successfully.`);
            }
        }
    } catch (error) {
        customErrorFunction(error);
        yield put({ type: ERROR_RESPONSE_DATA, payload: error+new Date() })
    }
}

async function bulkGetOrdersTagApi(data) {
    return axios.request({
        method: "GET",
        url: `${BASE_URL_ORDER}${API_URL.ORDER_TAGS_LIST_API}`,
        data: data
    });
}
function* bulkGetOrdersTagAction(action) {
    let { payload,  } = action;
    try {
        let response = yield call(bulkGetOrdersTagApi, payload);
        if (response.status === 200) {

            yield put({ type: BULK_ORDERS_TAG_LIST_DATA, payload: response?.data })
        }

    } catch (error) {
        customErrorFunction(error);
    }
}


//CLONE_ORDERS_API
async function cloneOrderApi(data) {
    let listData = axios.request({
        method: "POST",
        url: `${BASE_URL_ORDER}${API_URL.ORDER_CLONE_API}`,
        data: data.formData
    });
    return listData
}
function* cloneOrderAction(action) {
    let { payload, } = action;
    try {
        let response = yield call(cloneOrderApi, payload);
        if (response.status === 201) {
            yield put({ type: ORDERS_DETAILS_CLONE_DATA, payload: response?.status })
            yield put({ type: ORDERS_CLONE_RES_DATA, payload: response?.status })
            toast.success("Order Clone successfully")
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

async function createOrderTagApi(data) {
    let listData = axios.request({
        method: "POST",
        url: `${BASE_URL_ORDER}${API_URL.ORDER_TAG_CREATED_API}`,
        data: data
    });
    return listData
}
function* createOrderTagAction(action) {
    let { payload, } = action;
    try {
        let response = yield call(createOrderTagApi, payload);
        if (response.status === 201) {
            yield put({ type: ORDERS_TAG_LIST_API_ACTION })
            toast.success("Tag created successfully!")
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

async function GetOrdersSourceApi(data) {
    return axios.request({
        method: "GET",
        url: `${BASE_URL_ORDER}${API_URL.GET_ORDER_SOURCE_API}`,
        data: data
    });
}
function* GetOrdersSourceApiAction(action) {
    let { payload,  } = action;
    try {
        let response = yield call(GetOrdersSourceApi, payload);
        if (response.status === 200) {

            yield put({ type: ORDER_SOURCE_DATA, payload: response?.data })
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

async function GetOrdersDataApi(data) {
    return axios.request({
        method: "GET",
        url: `${BASE_URL_ORDER}${API_URL.GET_ORDERS_API}?courier_status=${data?.courier_status}&page=${data?.page}&page_size=${data?.page_size}`,
    });
}
function* GetOrdersDataAction(action) {
    let { payload,  } = action;
    try {
        let response = yield call(GetOrdersDataApi, payload);
        if (response.status === 200) {

            yield put({ type: ORDER_DATA, payload: response?.data })
        }

    } catch (error) {
        customErrorFunction(error);
    }
}

export function* ordersTabWatcher() {
    yield takeLatest(ORDERS_DETAILS_GET_ACTION, fetchOrderDataAction);
    yield takeLatest(ORDERS_DETAILS_UPDATE_ACTION, updateOrderAction);
    yield takeLatest(SAVE_FAVOURITE_ORDERS_ACTION, saveFavouriteOrdersAction);
    yield takeLatest(BULK_SHIP_ORDERS_ACTION, bulkShipOrdersAction);
    yield takeLatest(ORDERS_TAG_LIST_API_ACTION, bulkGetOrdersTagAction);
    yield takeLatest(GET_SAVE_FAVOURITE_ORDERS_ACTION, saveFavListAction);
    yield takeLatest(ORDERS_DETAILS_CLONE_ACTION, cloneOrderAction);
    yield takeLatest(CREATE_ORDERS_TAG_ACTION, createOrderTagAction);
    yield takeLatest(GET_ORDER_SOURCE_API_ACTION, GetOrdersSourceApiAction);
    yield takeLatest(GET_ORDER_DATA_ACTION, GetOrdersDataAction);



}
