import React from 'react'

const EmptyWalletIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={28}
                height={28}
                x={0}
                y={0}
                viewBox="0 0 32 32"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <g data-name="Layer 19">
                        <path
                            fill="#ff4d4d"
                            d="M20 11h10V9a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h18a5 5 0 0 0 5-5v-2H20a5 5 0 0 1 0-10zm0 2a3 3 0 0 0 0 6h10v-6zm0 5a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
                            opacity={1}
                            data-original="#ff8f00"
                            className="hovered-path"
                        />
                        <g fill="#ff6f00">
                            <path
                                d="M30 9v2h-3a15.05 15.05 0 0 0-1.36-3.95 15.46 15.46 0 0 0-2-3H25A5 5 0 0 1 30 9zM27.27 13H30v6h-3.57a15.23 15.23 0 0 0 .84-6zM25.55 21H30v2a5 5 0 0 1-5 5h-7.13c.37-.15.74-.32 1.1-.5a15.09 15.09 0 0 0 6.58-6.5z"
                                fill="#e60000"
                                opacity={1}
                                data-original="#ff6f00"
                                className=""
                            />
                        </g>
                    </g>
                </g>
            </svg>

        </>

    )
}

export default EmptyWalletIcon