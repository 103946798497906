import React from 'react'

const VerificationIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={80}
                height={80}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className="hovered-paths"
            >
                <g>
                    <path
                        d="M95.665 126.757V282.95c0 50.556 22.466 95.377 64.97 129.617 17.089 13.725 36.68 25.183 56.649 33.127 15.333 6.125 29.803 9.78 38.716 9.78 8.892 0 23.375-3.656 38.74-9.778 20.015-7.974 39.597-19.43 56.628-33.133 42.5-34.235 64.965-79.057 64.965-129.613V126.757L256 57.23zm193.7 96.294c0 9.838-4.406 19.175-11.769 25.445l7.855 64.845a7.994 7.994 0 0 1-7.936 8.955h-43.003a7.995 7.995 0 0 1-7.937-8.952l7.83-64.853c-7.363-6.28-11.77-15.617-11.77-25.44 0-18.397 14.967-33.364 33.364-33.364 18.398 0 33.366 14.967 33.366 33.364zM256 205.675c9.582 0 17.377 7.795 17.377 17.376 0 6.17-3.138 11.735-8.394 14.889a7.994 7.994 0 0 0-3.822 7.816l7.334 60.552H243.53l7.31-60.556a7.994 7.994 0 0 0-3.812-7.806c-5.262-3.17-8.405-8.738-8.405-14.895 0-9.581 7.796-17.376 17.377-17.376zm203.174-120.81L269.95 2.798c-8.604-3.73-19.296-3.731-27.903 0L52.843 84.859c-8.12 3.498-13.164 11.014-13.164 19.615V283.49c0 86.026 46.68 141.673 85.84 173.208C172.704 494.694 226.233 512 256 512s83.296-17.306 130.481-55.303c39.16-31.535 85.841-87.181 85.841-173.208V104.473c0-8.6-5.045-16.117-13.148-19.608zm-26.85 198.085c0 55.561-24.528 104.687-70.93 142.067-18.26 14.69-39.26 26.975-60.736 35.53-10.227 4.077-29.63 10.915-44.657 10.915-15.061 0-34.431-6.84-44.637-10.917-21.417-8.52-42.427-20.805-60.749-35.52-46.41-37.388-70.937-86.514-70.937-142.075V121.51a7.995 7.995 0 0 1 4.814-7.334l168.328-72.995a7.994 7.994 0 0 1 6.361 0l168.329 72.995a7.996 7.996 0 0 1 4.813 7.334v161.44z"
                        fill="#60a9eb"
                        opacity={1}
                        data-original="#000000"
                        className="hovered-path"
                    />
                </g>
            </svg>


        </>
    )
}

export default VerificationIcon