import React from 'react'

const TodayRevenueIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={32}
                height={32}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M475.148 130H409V37.6a8.121 8.121 0 0 0-10.8-7.54L317.7 58.6l-29.947-29.67a8.007 8.007 0 0 0-11.294.025L207.729 97.5 94.594 130h-.038c-4.418 0-7.556 3.942-7.556 8.36v161.1c-34 15.151-57.926 49.24-57.926 88.774A96.983 96.983 0 0 0 212.647 432h262.5a7.724 7.724 0 0 0 7.853-7.821V138.36c0-4.418-3.434-8.36-7.852-8.36ZM393 48.925V130h-2.915l-59.706-59.082Zm-110.866-3.39L367.355 130H197.67Zm-97.828 75.2L175.042 130h-22.8Zm-58.3 348.657a81.159 81.159 0 1 1 81.159-81.159 81.25 81.25 0 0 1-81.155 81.16ZM467 333h-52.28a51.5 51.5 0 1 1 0-103H467Zm0-119h-52.28a67.5 67.5 0 1 0 0 135H467v67H219.066a97.049 97.049 0 0 0-92.834-124.969A92.153 92.153 0 0 0 103 293.944V146h364Z"
                        fill="#f31429"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M376.6 281.616a28.231 28.231 0 1 0 28.23-28.23 28.262 28.262 0 0 0-28.23 28.23Zm40.461 0a12.231 12.231 0 1 1-12.231-12.23 12.245 12.245 0 0 1 12.23 12.23ZM163.405 344a8 8 0 0 0-8-8H94.6a8 8 0 0 0 0 16H112a17.958 17.958 0 0 1 16.2 10H94.6a8 8 0 0 0 0 16h33.6c-2.96 6-9.1 11-16.2 11H94.6a7.756 7.756 0 0 0-5.5 13.471l43.492 41.041a8.018 8.018 0 0 0 10.988-11.679l-28.98-27.611A33.382 33.382 0 0 0 145.136 378h10.269a8 8 0 0 0 0-16H145.13a40.686 40.686 0 0 0-4.26-10h14.535a8 8 0 0 0 8-8Z"
                        fill="#f31429"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>


        </>
    )
}

export default TodayRevenueIcon